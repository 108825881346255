import React, { useEffect, useState } from 'react';
import apiClient from "../../../tools/AxiosInterceptor";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import AircraftItem from './AircraftItem';
import { Heading } from "../../../catalystui/heading";

interface Aircraft {
    uuid: string;
    registration: string;
    manufacturer: string;
    model: string;
}

interface ExpiringDocument {
    registration: string;
    documentType: string;
    documentNumber?: string;
    expiryDate?: string;
    status: string;
    details: string;
}

interface UpcomingActivity {
    componentUuid: string;
    scheduleDescription: string;
    nextDueDate?: string;
    nextDueHours?: number;
    status: string;
    details: string;
}

const AircraftList: React.FC = () => {
    const [aircraft, setAircraft] = useState<Aircraft[]>([]);
    const [expiringDocuments, setExpiringDocuments] = useState<Record<string, ExpiringDocument[]>>({});
    const [upcomingActivities, setUpcomingActivities] = useState<Record<string, UpcomingActivity[]>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchAircraft = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found.');
            }

            const aircraftResponse = await apiClient.get<Aircraft[]>('/v1/getAircraft', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setAircraft(aircraftResponse.data);

            // Fetch expiring documents and upcoming activities for each aircraft
            const expiringDocumentsPromises = aircraftResponse.data.map(async (aircraft) => {
                try {
                    const response = await apiClient.get<{ upcomingActivities: ExpiringDocument[], upcomingActivitiesData: UpcomingActivity[] }>(
                        `/v1/getExpiringDocumentsAndUpcomingActivities/${aircraft.uuid}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    return {
                        uuid: aircraft.uuid,
                        documents: response.data.upcomingActivities || [],
                        activities: response.data.upcomingActivitiesData || [],
                    };
                } catch (err) {
                    console.error(`Error fetching data for aircraft ${aircraft.uuid}:`, err);
                    return { uuid: aircraft.uuid, documents: [], activities: [] };
                }
            });

            const expiringDocumentsResults = await Promise.all(expiringDocumentsPromises);

            // Organize fetched data into separate maps
            const documentsMap: Record<string, ExpiringDocument[]> = {};
            const activitiesMap: Record<string, UpcomingActivity[]> = {};

            expiringDocumentsResults.forEach((result) => {
                if (result) {
                    documentsMap[result.uuid] = result.documents;
                    activitiesMap[result.uuid] = result.activities;
                }
            });

            setExpiringDocuments(documentsMap);
            setUpcomingActivities(activitiesMap);

        } catch (err: any) {
            console.error('Error fetching aircraft:', err);
            setError(err.response?.data || err.message || 'Unable to fetch aircraft.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAircraft();
    }, []);

    return (
        <div>
            <Heading>Aircraft List</Heading>
            {loading && (
                <div className="flex items-center justify-center">
                    <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Loading...
                    </span>
                </div>
            )}
            {error && (
                <div className="rounded-md bg-red-50 p-4 mt-4 dark:bg-red-800">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-red-400 dark:text-red-300"
                            />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
                                {error}
                            </h3>
                        </div>
                    </div>
                </div>
            )}
            {!loading && !error && aircraft.length === 0 && (
                <p className="text-gray-700 dark:text-gray-300">No aircraft found.</p>
            )}
            {!loading && !error && aircraft.length > 0 && (
                <ul className="mt-10">
                    {aircraft.map((aircraftItem) => (
                        <AircraftItem
                            key={aircraftItem.uuid}
                            aircraft={aircraftItem}
                            expiringDocuments={expiringDocuments[aircraftItem.uuid] || []}
                            upcomingActivities={upcomingActivities[aircraftItem.uuid] || []}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AircraftList;
