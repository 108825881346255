// src/components/Dashboard/AircraftComponents/AircraftDetails.tsx

import React, {useState} from 'react';
import {Badge} from '../../../catalystui/badge';
import {
    Aircraft,
    Document,
    MaintenanceStatement,
    InsurancePolicy,
    CertificateOfReleaseToService
} from '../../../types';
import {Table, TableBody, TableRow, TableCell, TableHead, TableHeader} from "../../../catalystui/table";
import {Heading, Subheading} from "../../../catalystui/heading";
import {Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu} from '../../../catalystui/dropdown'
import {EllipsisHorizontalIcon, PlusCircleIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import {Button} from "../../../catalystui/button";
import CRSForm from "./CRSForm";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../catalystui/dialog";

interface ExpiringDocument {
    registration: string;
    documentType: string;
    documentNumber?: string;
    expiryDate?: string;
    status: string;
    details: string;
    componentUuid?: string;
    scheduleDescription?: string;
}

interface AircraftDetailsProps {
    aircraft: Aircraft;
    expiringDocuments: ExpiringDocument[]
}

const AircraftDetails: React.FC<AircraftDetailsProps> = ({aircraft, expiringDocuments}) => {
    const {components, documents} = aircraft;
    const [isCRSDialogOpen, setCRSDialogOpen] = useState(false);

    const handleCRSSubmit = (data: CertificateOfReleaseToService) => {
        console.log('CRS Form Data:', data);
        setCRSDialogOpen(false);
    };
    const formatDate = (isoDate: string): string => {
        const date = new Date(isoDate);
        return date.toLocaleDateString('en-UK', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };
    const getComponentBadge = (componentUuid: string) => {
        const matchedDocument = expiringDocuments.find(doc => doc.componentUuid === componentUuid);

        if (matchedDocument) {
            return (
                <Badge color="orange">
                    {matchedDocument.scheduleDescription}: {matchedDocument.status}
                </Badge>
            );
        }
        return <Badge color="green">All Good</Badge>;
    };

    return (
        <div className="mx-auto">
            <div className="pb-4">
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <Heading>{aircraft.registration}</Heading>
                        <Subheading>{aircraft.manufacturer} {aircraft.model}</Subheading>
                        <Badge color="sky">{aircraft.mthCounter} MTH</Badge>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">

            <span className="hidden sm:block sm:ml-3">
              <Button outline href={`/dashboard/aircraft/${aircraft.uuid}/issues`}>
                Issues
              </Button>
            </span>
                        <span className="sm:ml-3">
              <Button onClick={() => setCRSDialogOpen(true)}>
                <PlusCircleIcon className="h-5 w-5 mr-2"/>
                Add CRS
              </Button>
            </span>
                        <div className="relative ml-3 sm:hidden">
                            <Dropdown>
                                <DropdownButton outline>
                                    More
                                    <ChevronDownIcon className="ml-2 h-5 w-5"/>
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem href={`/dashboard/aircraft/${aircraft.uuid}/issues`}>
                                        <DropdownLabel>Issues</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <CRSForm
                onSubmit={handleCRSSubmit}
                aircraft={aircraft}
                isOpen={isCRSDialogOpen}
                onClose={() => setCRSDialogOpen(false)}
            />

            {documents && documents.length > 0 ? (
                <>
                    <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
                        {documents.map((doc: Document | MaintenanceStatement | InsurancePolicy | CertificateOfReleaseToService, index) => {
                            if (doc.documentType === "Maintenance Statement" && "crsNumber" in doc) {
                                // Render Maintenance Statement details
                                const maintenanceDoc = doc as MaintenanceStatement;
                                return (
                                    <div key={index}>
                                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                                        <div className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">
                                            {maintenanceDoc.documentType} {maintenanceDoc.documentNumber}
                                        </div>
                                        <div className="mt-6 text-s font-s text-gray-900 dark:text-gray-100">
                                            Next service
                                        </div>
                                        <div
                                            className="mt-1 text-2xl font-semibold text-gray-900 dark:text-gray-100 flex items-baseline">
                                            {maintenanceDoc.validTillMTH} MTH
                                            <span className="ml-1 text-md font-normal text-gray-900 dark:text-gray-100"
                                                  style={{fontSize: '0.8rem'}}>
                                ±{maintenanceDoc.validTillMTHTolerance} MTH
                            </span>
                                        </div>
                                        <div
                                            className="mt-1 text-2xl font-semibold text-gray-900 dark:text-gray-100 flex items-baseline">
                                            {formatDate(maintenanceDoc.validTillDate)}
                                            <span className="ml-1 text-md font-normal text-gray-900 dark:text-gray-100"
                                                  style={{fontSize: '0.8rem'}}>
                                ±{maintenanceDoc.validTillDateDaysTolerance} days
                            </span>
                                        </div>
                                        <div className="mt-3 text-sm space-y-2">
                                            {/* MTH Counter Badge */}
                                            {(() => {
                                                const mthDifference = maintenanceDoc.validTillMTH - aircraft.mthCounter;
                                                let mthBadgeColor: 'yellow' | 'orange' | 'red' | 'lime' = 'lime';
                                                if (mthDifference < 0) {
                                                    mthBadgeColor = 'red';
                                                } else if (mthDifference <= maintenanceDoc.validTillMTHTolerance) {
                                                    mthBadgeColor = 'orange';
                                                } else if (mthDifference < 10) {
                                                    mthBadgeColor = 'yellow';
                                                }
                                                return (
                                                    <div>
                                                        <Badge color={mthBadgeColor}>
                                                            MTH Remaining: {mthDifference.toFixed(1)} MTH
                                                        </Badge>
                                                    </div>
                                                );
                                            })()}

                                            {/* Date Badge */}
                                            {(() => {
                                                const currentDate = new Date();
                                                const validTillDate = new Date(maintenanceDoc.validTillDate);
                                                const daysToExpiry = (validTillDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);
                                                let dateBadgeColor: 'yellow' | 'orange' | 'red' | 'lime' = 'lime';
                                                if (daysToExpiry < 0) {
                                                    dateBadgeColor = 'red';
                                                } else if (daysToExpiry <= maintenanceDoc.validTillDateDaysTolerance) {
                                                    dateBadgeColor = 'orange';
                                                } else if (daysToExpiry < 10) {
                                                    dateBadgeColor = 'yellow';
                                                }
                                                return (
                                                    <div>
                                                        <Badge color={dateBadgeColor}>
                                                            Days to Expiry: {Math.ceil(daysToExpiry)}
                                                        </Badge>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                );
                            } else if (doc.documentType === "Insurance Policy" && "validFromDate" in doc) {
                                // Render Insurance Policy details
                                const insuranceDoc = doc as InsurancePolicy;
                                return (
                                    <div key={index}
                                         className="w-full border-t border-zinc-950/10 dark:border-white/10">
                                        <div className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">
                                            {insuranceDoc.documentType} {insuranceDoc.documentNumber}
                                        </div>
                                        <div className="mt-6 text-s font-s text-gray-900 dark:text-gray-100">
                                            Valid Till
                                        </div>
                                        <div
                                            className="mt-1 text-2xl font-semibold text-gray-900 dark:text-gray-100 flex items-baseline">
                                            {formatDate(insuranceDoc.validTillDate)}
                                        </div>

                                        <div className="mt-3 text-xs text-gray-700 dark:text-gray-300">
                                            Territorial Scope: {insuranceDoc.territorialScopeOfFlights}
                                        </div>
                                        <div className="mt-3 text-xs text-gray-700 dark:text-gray-300">
                                            Type of Insured Flights: {insuranceDoc.typeOfInsuredFlights}
                                        </div>
                                        <div className="mt-3 text-sm space-y-2">
                                            {/* Date Badge */}
                                            {(() => {
                                                const currentDate = new Date();
                                                const validTillDate = new Date(insuranceDoc.validTillDate);
                                                const daysToExpiry = (validTillDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);
                                                let dateBadgeColor: 'yellow' | 'orange' | 'red' | 'lime' = 'lime';
                                                if (daysToExpiry < 0) {
                                                    dateBadgeColor = 'red';
                                                } else if (daysToExpiry <= 10) {
                                                    dateBadgeColor = 'orange';
                                                } else if (daysToExpiry < 30) {
                                                    dateBadgeColor = 'yellow';
                                                }
                                                return (
                                                    <div>
                                                        <Badge color={dateBadgeColor}>
                                                            Days to Expiry: {Math.ceil(daysToExpiry)}
                                                        </Badge>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                );
                            } else if (doc.documentType === "Certificate of Release to Service" && "maintenanceTasksPerformed" in doc) {
                                const crsDoc = doc as CertificateOfReleaseToService;
                                return (
                                    <div key={index}
                                         className="w-full border-t border-zinc-950/10 dark:border-white/10">
                                        <div className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">
                                            {crsDoc.documentType} {crsDoc.documentNumber}
                                        </div>

                                        {/* Maintenance Tasks Performed */}
                                        <div className="mt-6">
                                            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">Maintenance
                                                Tasks Performed</h4>
                                            <ul className="mt-2 text-xs space-y-1 text-gray-700 dark:text-gray-300">
                                                {crsDoc.maintenanceTasksPerformed.map((task, idx) => (
                                                    <li key={idx}>
                                                        <span
                                                            className="font-semibold">{task.item}:</span> {task.comment}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Parts Removed */}
                                        <div className="mt-4">
                                            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">Parts
                                                Removed</h4>
                                            <ul className="mt-2 text-xs space-y-1 text-gray-700 dark:text-gray-300">
                                                {crsDoc.partsRemoved.map((part, idx) => (
                                                    <li key={idx}>
                                                        <span className="font-semibold">{part.description}</span> -
                                                        P/N: {part.partNumber}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Parts Installed */}
                                        <div className="mt-4">
                                            <h4 className="text-sm font-semibold text-gray-900 dark:text-gray-100">Parts
                                                Installed</h4>
                                            <ul className="mt-2 text-xs space-y-1 text-gray-700 dark:text-gray-300">
                                                {crsDoc.partsInstalled.map((part, idx) => (
                                                    <li key={idx}>
                                                        <span className="font-semibold">{part.description}</span> -
                                                        P/N: {part.partNumber}, EASA Form 1: {part.easaForm}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {/* Badge for Release to Service */}
                                        <div className="mt-4 text-xs">
                                            <Badge color="lime">
                                                Released
                                                on {formatDate(crsDoc.releaseToService.date)} by {crsDoc.releaseToService.certifyingStaff}
                                            </Badge>
                                        </div>
                                    </div>
                                );
                            } else {
                                // Generic display for other document types
                                return (
                                    <div key={index} className="p-4 border rounded-lg shadow bg-white dark:bg-gray-800">
                                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                                        <div className="mt-6 text-lg font-medium text-gray-900 dark:text-gray-100">
                                            {doc.documentType} {doc.documentNumber}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </>
            ) : (
                <div className="mt-4 p-4 bg-yellow-100 dark:bg-yellow-900 rounded-lg shadow">
                    <p className="text-gray-800 dark:text-gray-200">No documents found for this aircraft.</p>
                </div>
            )}


            {/* Installed Components */}
            {components && components.length > 0 ? (
                <div className="mt-8">
                    <Heading>
                        Components
                    </Heading>
                    <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]" dense>
                        <TableHead>
                            <TableRow>
                                <TableHeader className="text-xs">Title</TableHeader>
                                <TableHeader className="hidden text-xs sm:table-cell">Serial Number</TableHeader>
                                <TableHeader className="text-xs">Current Time</TableHeader>
                                <TableHeader className="text-xs">Status</TableHeader>
                                <TableHeader className="relative w-0">
                                    <span className="sr-only">Actions</span>
                                </TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {components.map((component) => (
                                <TableRow key={component.uuid}
                                          href={`/dashboard/aircraft/${aircraft.uuid}/component/${component.uuid}`}>
                                    <TableCell
                                        className="font-medium text-xs">{component.title} {component.partNumber}</TableCell>
                                    <TableCell
                                        className="hidden text-xs sm:table-cell">{component.serialNumber}</TableCell>
                                    <TableCell className="text-zinc-500 text-xs">{component.currentHours}</TableCell>

                                    {/* Conditionally render the badge */}
                                    <TableCell className="text-xs">
                                        {getComponentBadge(component.uuid)}
                                    </TableCell>

                                    <TableCell>
                                        <div>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options">
                                                    <EllipsisHorizontalIcon/>
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem>View</DropdownItem>
                                                    <DropdownItem>Edit</DropdownItem>
                                                    <DropdownItem>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div className="mt-8 p-4 bg-yellow-100 dark:bg-yellow-900 rounded-lg shadow">
                    <p className="text-gray-800 dark:text-gray-200">
                        No installed components found for this aircraft.
                    </p>
                </div>
            )}
        </div>
    );
};
export default AircraftDetails;

