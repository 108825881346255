import React, { useEffect, useState } from 'react';
import apiClient from "../../tools/AxiosInterceptor";
import { UserCircleIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import md5 from "md5";
import { User, Flight } from "../../types";
import { Heading } from "../../catalystui/heading";
import { Badge } from "../../catalystui/badge";
import UserFlightsTable from "./UserFlightListSection";
import {Divider} from "../../catalystui/divider";

const UserProfileSection = () => {
    const [user, setUser] = useState<User | null>(null);
    const [userFlights, setUserFlights] = useState<Flight[]>([]);
    const [activeSection, setActiveSection] = useState<string>('Profile');
    const [allUserMTH, setAllUserMTH] = useState<{ uuid: string, mth: number }[]>([]);
    const [allUserFlightTime, setAllUserFlightTime] = useState<{ uuid: string, flightTime: number }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const getEmailHash = (email: string) => {
        return md5(email.trim().toLowerCase());
    };

    const userEmail = sessionStorage.getItem('email');
    const imageSize = 200;
    const gravatarUrl = userEmail
        ? `https://www.gravatar.com/avatar/${getEmailHash(userEmail)}?s=${imageSize}`
        : `https://www.gravatar.com/avatar/?d=identicon&s=${imageSize}`;

    const handleChangeAvatar = () => {
        window.open('https://en.gravatar.com/emails/', '_blank');
    };

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ');
    }

    const formatTimeInHHMM = (totalMinutes: number): string => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    };

    const padToTwoDigits = (number: number): string => {
        return number.toString().padStart(2, '0');
    };


    const calculateTotalFlightTime = (flights: Flight[]): number => {
        return flights.reduce((total: number, flight: Flight) => {
            return total + parseFlightTimeToMinutes(flight.flight_time);
        }, 0);
    };

    const calculateTotalMTH = (flights: Flight[]): number => {
        return flights.reduce((total: number, flight: Flight) => total + flight.engine_time_difference, 0);
    };

    const parseFlightTimeToMinutes = (timeString: string): number => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours * 60) + minutes;
    };

    useEffect(() => {
        const fetchUserDataAndFlights = async () => {
            try {
                const userUuid = sessionStorage.getItem('user_uuid');
                if (!userUuid) {
                    console.error("User UUID not found in sessionStorage");
                    return;
                }

                setLoading(true);

                const [userResponse, flightsResponse] = await Promise.all([
                    apiClient.get<User>(`getUser/${userUuid}`, {
                        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` }
                    }),
                    apiClient.get<{ entries: Flight[] }>('/getFlights', {
                        headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` }
                    })
                ]);

                setUser(userResponse.data);

                const userFlights = flightsResponse.data.entries.filter(flight => flight.pic_user_uuid === userUuid);
                setUserFlights(userFlights);

                const allFlights = flightsResponse.data.entries;

                // Calculate total MTH for all users
                const mthData = allFlights.reduce((acc: { [key: string]: number }, flight) => {
                    acc[flight.pic_user_uuid] = (acc[flight.pic_user_uuid] || 0) + flight.engine_time_difference;
                    return acc;
                }, {});

                const flightTimeData = allFlights.reduce((acc: { [key: string]: number }, flight) => {
                    acc[flight.pic_user_uuid] = (acc[flight.pic_user_uuid] || 0) + parseFlightTimeToMinutes(flight.flight_time);
                    return acc;
                }, {});

                setAllUserMTH(Object.keys(mthData).map(uuid => ({ uuid, mth: mthData[uuid] })));
                setAllUserFlightTime(Object.keys(flightTimeData).map(uuid => ({ uuid, flightTime: flightTimeData[uuid] })));

                setLoading(false);
            } catch (error) {
                console.error("Error fetching user or flights data:", error);
                setLoading(false);
                setError("Failed to fetch data. Please try again later.");
            }
        };

        fetchUserDataAndFlights();
    }, []);

    const getUserMTHRanking = (): number | null => {
        if (!user) return null;
        const sortedMTHList = [...allUserMTH].sort((a, b) => b.mth - a.mth);
        return sortedMTHList.findIndex(entry => entry.uuid === user.uuid) + 1;
    };

    const getUserFlightTimeRanking = (): number | null => {
        if (!user) return null;

        // Calculate the user's total flight time
        const userTotalFlightTime = calculateTotalFlightTime(userFlights);
        console.log("User Total Flight Time (in minutes):", userTotalFlightTime);

        // Ensure the array is sorted correctly for ranking
        const sortedFlightTimeList = [...allUserFlightTime].sort((a, b) => b.flightTime - a.flightTime);
        console.log("Sorted Flight Time List:", sortedFlightTimeList);

        // Find the current user's rank based on their UUID
        const userRank = sortedFlightTimeList.findIndex(entry => entry.uuid === user.uuid) + 1;
        console.log("User UUID:", user.uuid);
        console.log("User Rank by Flight Time:", userRank);

        return userRank;
    };

    const userMTH = calculateTotalMTH(userFlights);
    const userMTHRanking = getUserMTHRanking();
    const userFlightTime = calculateTotalFlightTime(userFlights);
    const userFlightTimeRanking = getUserFlightTimeRanking();

    return (
        <div>
            <Heading>Hello, {user?.first_name}</Heading>

            <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
                <div>
                    <hr className="w-full border-t border-zinc-950/10 dark:border-white/10" />
                    <div className="mt-6 text-lg font-medium text-gray-900 dark:text-gray-100">Your Total MTH</div>
                    <div className="mt-3 text-3xl font-semibold text-gray-900 dark:text-gray-100">{userMTH.toFixed(1)}</div>
                    <div className="mt-3 text-sm">
                        <Badge color="lime">Rank {userMTHRanking}</Badge>
                    </div>
                </div>
                <div>
                    <hr className="w-full border-t border-zinc-950/10 dark:border-white/10" />
                    <div className="mt-6 text-lg font-medium text-gray-900 dark:text-gray-100">Your Total Flight Time</div>
                    <div className="mt-3 text-3xl font-semibold text-gray-900 dark:text-gray-100">{formatTimeInHHMM(userFlightTime)}</div>
                    <div className="mt-3 text-sm">
                        <Badge color="lime">Rank {userFlightTimeRanking}</Badge>
                    </div>
                </div>
            </div>
            <Heading className="mt-6">Flights</Heading>
            <Divider className="mt-3"/>
            <UserFlightsTable flights={userFlights}/>

            {loading && <p>Loading data...</p>}
            {error && <p className="text-red-500">{error}</p>}
        </div>
    );
};

export default UserProfileSection;
