import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';
import './App.css';
import LoginScreen from "./components/Auth/LoginScreen";
import LandingScreen from "./components/LandingScreen/LandingScreen";
import DashboardScreen from "./components/Dashboard/DashboardScreen";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import PrivacyPolicy from "./components/LandingScreen/PrivacyPolicy";
import './tools/i18n'
import {ThemeProvider} from "next-themes";

const AppRoutes: React.FC = () => {
    const {i18n} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const language = location.pathname.startsWith('/en') ? 'en' : 'pl';
        i18n.changeLanguage(language);
    }, [i18n, location.pathname]);

    return (
        <Routes>
            <Route path="/" element={<LandingScreen/>}/>
            <Route path="/en/*" element={<LandingScreen/>}/>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/dashboard/*" element={<ProtectedRoute element={<DashboardScreen/>}/>}/>
            <Route path="*" element={<LandingScreen/>}/>
        </Routes>
    );
};

const App: React.FC = () => {
    amplitude.init('950de26e2992827be775c2591a7eb06e', {serverZone: 'EU'});

    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);

    return (
        <ThemeProvider>
            <Router>
                <AppRoutes/>
            </Router>
        </ThemeProvider>
    );
};

export default App;
