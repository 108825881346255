import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableRow, TableCell, TableHead, TableHeader } from '../../../catalystui/table';
import { Heading, Subheading } from '../../../catalystui/heading';
import { Badge } from "../../../catalystui/badge";
import { Text } from '../../../catalystui/text';
import { InstalledComponent, MaintenanceSchedule, ADSBCompliance, ADSBComplianceHistoryRecord } from '../../../types';
import apiClient from '../../../tools/AxiosInterceptor';

const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    return date.toLocaleDateString('en-UK', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

const getLastPerformedEntry = (history: MaintenanceSchedule['history']) => {
    return history
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0] || null;
};

const ComponentDetails: React.FC = () => {
    const { componentUuid, uuid } = useParams<{ componentUuid: string, uuid: string }>();
    const [componentDetails, setComponentDetails] = useState<InstalledComponent | null>(null);
    const [expandedSchedule, setExpandedSchedule] = useState<{ [key: string]: boolean }>({});
    const [expandedCompliance, setExpandedCompliance] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchComponentDetails = async () => {
            try {
                const response = await apiClient.get(`/v1/aircraft/${uuid}/component/${componentUuid}`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                });
                setComponentDetails(response.data);
            } catch (error) {
                console.error('Error fetching component details:', error);
            }
        };

        fetchComponentDetails();
    }, [uuid, componentUuid]);

    const toggleScheduleHistory = (scheduleUuid: string) => {
        setExpandedSchedule((prev) => ({
            ...prev,
            [scheduleUuid]: !prev[scheduleUuid],
        }));
    };

    const toggleComplianceHistory = (complianceUuid: string) => {
        setExpandedCompliance((prev) => ({
            ...prev,
            [complianceUuid]: !prev[complianceUuid],
        }));
    };

    if (!componentDetails) {
        return <p>Loading component details...</p>;
    }

    return (
        <div>
            <Heading>{componentDetails.title} {componentDetails.partNumber}</Heading>
            <Text>Serial Number: {componentDetails.serialNumber}</Text>
            <Badge color="sky">Current Time: {componentDetails.currentHours}</Badge>

            {/* Maintenance Schedule Section */}
            <Subheading className="mt-6">Maintenance Schedule</Subheading>
            <Table className="mt-4">
                <TableHead>
                    <TableRow>
                        <TableHeader className="text-xs">Description</TableHeader>
                        <TableHeader className="text-xs">Cycle</TableHeader>
                        <TableHeader className="text-xs">Last Performed</TableHeader>
                        <TableHeader className="text-xs">Expiry Date</TableHeader>
                        <TableHeader className="text-xs">Actions</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {componentDetails.maintenanceSchedule.map((schedule) => {
                        const lastPerformedEntry = getLastPerformedEntry(schedule.history);
                        const isExpanded = expandedSchedule[schedule.uuid] || false;

                        return (
                            <React.Fragment key={schedule.uuid}>
                                <TableRow>
                                    <TableCell className="text-xs font-medium text-gray-900 dark:text-gray-100">
                                        {schedule.description}
                                    </TableCell>
                                    <TableCell className="text-xs text-gray-700 dark:text-gray-300">
                                        {schedule.cycle.map((cycle, index) => (
                                            <div key={index}>{`${cycle.type}: ${cycle.threshold}`}</div>
                                        ))}
                                    </TableCell>
                                    <TableCell className="text-xs text-gray-700 dark:text-gray-300">
                                        {lastPerformedEntry ? formatDate(lastPerformedEntry.date) : 'N/A'}
                                    </TableCell>
                                    <TableCell className="text-xs text-gray-700 dark:text-gray-300">
                                        {lastPerformedEntry?.expiryDate ? formatDate(lastPerformedEntry.expiryDate) : 'N/A'}
                                    </TableCell>
                                    <TableCell className="text-xs text-blue-500">
                                        <button onClick={() => toggleScheduleHistory(schedule.uuid)}>
                                            {isExpanded ? 'Hide History' : 'Show History'}
                                        </button>
                                    </TableCell>
                                </TableRow>

                                {/* Expanded history in a styled div within a single TableCell */}
                                {isExpanded && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <div className="rounded-md shadow-sm bg-gray-100 dark:bg-zinc-800 p-4 mt-2">
                                                <Table dense>
                                                    <TableHead>
                                                        <TableRow className="text-xs">
                                                            <TableHeader>Date</TableHeader>
                                                            <TableHeader>Hours at Maintenance</TableHeader>
                                                            <TableHeader>Remarks</TableHeader>
                                                            <TableHeader>Confirmation Document</TableHeader>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {schedule.history.map((historyItem) => (
                                                            <TableRow className="text-xs" key={historyItem.confirmationDocument}>
                                                                <TableCell>{formatDate(historyItem.date)}</TableCell>
                                                                <TableCell>{historyItem.hoursAtMaintenance}</TableCell>
                                                                <TableCell>{historyItem.remarks}</TableCell>
                                                                <TableCell>{historyItem.confirmationDocument}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>

            {/* AD/SB Compliance Section */}
            <Subheading className="mt-6">AD/SB Compliance</Subheading>
            <Table dense className="mt-4">
                <TableHead>
                    <TableRow>
                        <TableHeader className="text-xs">Directive ID</TableHeader>
                        <TableHeader className="text-xs">Subject</TableHeader>
                        <TableHeader className="text-xs">Method</TableHeader>
                        <TableHeader className="text-xs">Repeatable</TableHeader>
                        <TableHeader className="text-xs">Actions</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {componentDetails.adsbCompliance.map((ad) => {
                        const isExpanded = expandedCompliance[ad.uuid] || false;

                        return (
                            <React.Fragment key={ad.uuid}>
                                <TableRow>
                                    <TableCell className="text-xs font-medium text-gray-900 dark:text-gray-100">
                                        {ad.airworthinessDirectiveId || ad.serviceBulletinId}
                                    </TableCell>
                                    <TableCell className="text-xs text-gray-700 dark:text-gray-300">
                                        {ad.subject}
                                    </TableCell>
                                    <TableCell className="text-xs text-gray-700 dark:text-gray-300">
                                        {ad.method}
                                    </TableCell>
                                    <TableCell className="text-xs text-gray-700 dark:text-gray-300">
                                        {ad.repeatable ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell className="text-xs text-blue-500">
                                        <button onClick={() => toggleComplianceHistory(ad.uuid)}>
                                            {isExpanded ? 'Hide History' : 'Show History'}
                                        </button>
                                    </TableCell>
                                </TableRow>

                                {/* Expanded compliance history in a styled div */}
                                {isExpanded && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <div className="rounded-md shadow-sm bg-gray-100 dark:bg-zinc-800 p-4 mt-2">
                                                <Table dense>
                                                    <TableHead>
                                                        <TableRow className="text-xs">
                                                            <TableHeader>Date</TableHeader>
                                                            <TableHeader>Confirmed</TableHeader>
                                                            <TableHeader>Flight Hours</TableHeader>
                                                            <TableHeader>Details</TableHeader>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ad.complianceHistory.map((record: ADSBComplianceHistoryRecord) => (
                                                            <TableRow className="text-xs" key={record.uuid}>
                                                                <TableCell>{formatDate(record.date)}</TableCell>
                                                                <TableCell>
                                                                    <Badge color={record.confirmed ? 'lime' : 'red'}>
                                                                        {record.confirmed ? 'Yes' : 'No'}
                                                                    </Badge>
                                                                </TableCell>
                                                                <TableCell>{record.flightHoursPerformed ?? 'N/A'}</TableCell>
                                                                <TableCell>{record.details}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

export default ComponentDetails;
