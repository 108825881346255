import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import apiClient from "../../../../tools/AxiosInterceptor";
import {Heading, Subheading} from "../../../../catalystui/heading";
import {Text} from "../../../../catalystui/text";
import {Button} from "../../../../catalystui/button";
import {Input} from "../../../../catalystui/input";
import {Select} from "../../../../catalystui/select";

interface FuelTransaction {
    uuid: string;
    transactionDate: string;
    transactionType: string;
    transactionAmount: number;
    transactionStatusBefore: number;
    transactionStatusAfter: number;
    transactionUnits: string;
    transactionNetCostPerUnit: number;
    transactionCurrency: string;
    transactionUser: string;
    modifiedAt: string;
    modifiedByUserUuid: string;
}

interface AddFuelTransactionSectionProps {
    onSaveOrCancel: () => void;
    fuelTransactionData?: FuelTransaction;
    latestTransaction?: FuelTransaction; // Add latestTransaction to the props
}

const FuelTransactionForm: React.FC<AddFuelTransactionSectionProps> = ({ onSaveOrCancel, fuelTransactionData, latestTransaction }) => {
    const [formValues, setFormValues] = useState<FuelTransaction>({
        uuid: '',
        transactionDate: '',
        transactionType: 'Taken',
        transactionAmount: 0,
        transactionStatusBefore: latestTransaction ? latestTransaction.transactionStatusAfter : 0, // Set initial value from latestTransaction
        transactionStatusAfter: 0,
        transactionUnits: 'L',
        transactionNetCostPerUnit: 7.5,
        transactionCurrency: 'PLN',
        transactionUser: '',
        modifiedAt: '',
        modifiedByUserUuid: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormValues(prevValues => {
            const updatedValues = { ...prevValues, [name]: value };

            // Calculate transactionStatusAfter based on transactionType and transactionAmount
            if (name === "transactionType" || name === "transactionAmount" || name === "transactionStatusBefore") {
                const amountBefore = parseFloat(updatedValues.transactionStatusBefore.toString());
                const amount = parseFloat(updatedValues.transactionAmount.toString());
                updatedValues.transactionStatusAfter =
                    updatedValues.transactionType === "Added"
                        ? amountBefore + amount
                        : amountBefore - amount;
            }

            return updatedValues;
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const userUuid = sessionStorage.getItem('user_uuid');

        function safelyParseFloat(value: string | number): number {
            return typeof value === 'string' ? parseFloat(value) : value;
        }

        let submissionValues = { ...formValues };
        submissionValues.transactionAmount = safelyParseFloat(submissionValues.transactionAmount);
        submissionValues.transactionStatusBefore = safelyParseFloat(submissionValues.transactionStatusBefore);
        submissionValues.transactionStatusAfter = safelyParseFloat(submissionValues.transactionStatusAfter);
        submissionValues.transactionNetCostPerUnit = safelyParseFloat(submissionValues.transactionNetCostPerUnit);

        if (!formValues.uuid) {
            submissionValues.uuid = uuidv4();
        }

        if (formValues.transactionDate) {
            submissionValues.transactionDate = new Date(formValues.transactionDate).toISOString();
        }

        submissionValues.modifiedAt = new Date().toISOString();

        if (userUuid) {
            submissionValues.modifiedByUserUuid = userUuid;
        }

        try {
            const response = await apiClient.post('/addFuelTransaction', submissionValues, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);
            console.log('Transaction added:', response.data);
        } catch (error: unknown) {
            console.error('An error occurred during the API call:', error);
        }
        onSaveOrCancel();
    };

    const toShortDateFormat = (isoString: string) => {
        return isoString.split('T')[0];
    };

    useEffect(() => {
        if (fuelTransactionData) {
            setFormValues({
                uuid: fuelTransactionData.uuid ?? '',
                transactionDate: toShortDateFormat(fuelTransactionData.transactionDate) ?? '',
                transactionType: fuelTransactionData.transactionType ?? 'Taken',
                transactionAmount: fuelTransactionData.transactionAmount ?? 0,
                transactionStatusBefore: fuelTransactionData.transactionStatusBefore ?? 0,
                transactionStatusAfter: fuelTransactionData.transactionStatusAfter ?? 0,
                transactionUnits: fuelTransactionData.transactionUnits ?? 'L',
                transactionNetCostPerUnit: fuelTransactionData.transactionNetCostPerUnit ?? 7.5,
                transactionCurrency: fuelTransactionData.transactionCurrency ?? 'PLN',
                transactionUser: fuelTransactionData.transactionUser ?? '',
                modifiedAt: fuelTransactionData.modifiedAt ?? '',
                modifiedByUserUuid: fuelTransactionData.modifiedByUserUuid ?? '',
            });
        } else if (latestTransaction) {
            setFormValues(prevValues => ({
                ...prevValues,
                transactionStatusBefore: latestTransaction.transactionStatusAfter
            }));
        }
    }, [fuelTransactionData, latestTransaction]);

    return (
        <main>
            <div>
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <form onSubmit={handleSubmit} className="space-y-8">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                            <div className="px-4 sm:px-0">
                                <Subheading>Fuel Transaction</Subheading>
                                <Text>Date, type, who performed the operation</Text>
                            </div>
                            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 dark:bg-zinc-800">
                                <div className="px-4 py-4 sm:p-8">
                                    <div className="grid max-w-2xl py-4 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionDate" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Date
                                            </label>
                                            <div className="mt-2">
                                                <Input
                                                    type="date"
                                                    name="transactionDate"
                                                    id="transactionDate"
                                                    autoComplete="transactionDate"
                                                    step="1"
                                                    value={formValues.transactionDate}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionType" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Type (Added / Taken)
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    name="transactionType"
                                                    id="transactionType"
                                                    value={formValues.transactionType}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Added">Added</option>
                                                    <option value="Taken">Taken</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionAmount" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Amount
                                            </label>
                                            <div className="mt-2">
                                                <Input
                                                    type="number"
                                                    name="transactionAmount"
                                                    id="transactionAmount"
                                                    autoComplete="transactionAmount"
                                                    step="1"
                                                    value={formValues.transactionAmount}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionUnits" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Units
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    name="transactionUnits"
                                                    id="transactionUnits"
                                                    value={formValues.transactionUnits}
                                                    onChange={handleChange}
                                                >
                                                    <option value="L">L</option>
                                                    <option value="US Gal">US Gal</option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-4 sm:p-8">
                                    <div className="grid max-w-2xl py-4 grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionStatusBefore" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Amount Before
                                            </label>
                                            <div className="mt-2">
                                                <Input
                                                    type="number"
                                                    name="transactionStatusBefore"
                                                    id="transactionStatusBefore"
                                                    autoComplete="transactionStatusBefore"
                                                    step="1"
                                                    value={formValues.transactionStatusBefore}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionStatusAfter" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Amount After
                                            </label>
                                            <div className="mt-2">
                                                <Input
                                                    type="number"
                                                    name="transactionStatusAfter"
                                                    id="transactionStatusAfter"
                                                    autoComplete="transactionStatusAfter"
                                                    step="1"
                                                    value={formValues.transactionStatusAfter}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionNetCostPerUnit" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Fuel Unit Price
                                            </label>
                                            <div className="mt-2">
                                                <Input
                                                    type="number"
                                                    name="transactionNetCostPerUnit"
                                                    id="transactionNetCostPerUnit"
                                                    autoComplete="transactionNetCostPerUnit"
                                                    step="0.1"
                                                    value={formValues.transactionNetCostPerUnit}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionCurrency" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Currency
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    name="transactionCurrency"
                                                    id="transactionCurrency"
                                                    value={formValues.transactionCurrency}
                                                    onChange={handleChange}
                                                >
                                                    <option value="PLN">PLN</option>
                                                    <option value="EUR">EUR</option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="transactionUser" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Who performed
                                            </label>
                                            <div className="mt-2">

                                                <Input
                                                    type="text"
                                                    name="transactionUser"
                                                    id="transactionUser"
                                                    autoComplete="transactionUser"
                                                    value={formValues.transactionUser}
                                                    onChange={handleChange}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6 gap-y-8 p-10">
                            <Button
                                outline
                                type="button"
                                onClick={onSaveOrCancel}
                                className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
};

export default FuelTransactionForm;
