import React, {useEffect, useState} from 'react';
import {CheckIcon, ChevronUpDownIcon, ExclamationTriangleIcon} from '@heroicons/react/20/solid'
import {Combobox} from '@headlessui/react'
import apiClient from "../../tools/AxiosInterceptor";
import {v4 as uuidv4} from 'uuid';
import {Aircraft, Flight, User} from "../../types"
import {toShortDateFormat} from "../../tools/utils"
import {useNavigate} from 'react-router-dom';
import {classNames} from "../../tools/utils";
import {Field, FieldGroup, Fieldset, Label, Legend} from "../../catalystui/fieldset";
import {Text} from "../../catalystui/text";
import {Select} from "../../catalystui/select";
import {Heading, Subheading} from "../../catalystui/heading";
import {Button} from "../../catalystui/button";

interface AddFlightSectionProps {
    flightData?: Flight; // Make it optional as it may not always be provided
}

interface UsersResponse {
    users: User[];
}

const AddFlightSection: React.FC<AddFlightSectionProps> = ({flightData}) => {
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];
    const [error, setError] = useState<string | null>(null);
    const formatDateForInput = (dateString: string) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  // Get 'YYYY-MM-DD' format
    };
    const [formValues, setFormValues] = useState<Flight>({
        aircraft_registration: flightData ? flightData.aircraft_registration : '',
        flight_date: flightData ? formatDateForInput(flightData.flight_date) : today,
        departure_airfield: flightData?.departure_airfield || '',
        arrival_airfield: flightData?.arrival_airfield || '',
        pilot_in_command: flightData?.pilot_in_command || '',
        license_number: flightData?.license_number || '',
        departure_time: flightData?.departure_time || '00:00',
        arrival_time: flightData?.arrival_time || '00:00',
        flight_time: flightData?.flight_time || '00:00',
        ground_time: flightData?.ground_time || '00:00',
        number_of_landings: flightData?.number_of_landings || 1,
        engine_time_before_flight: flightData?.engine_time_before_flight || 0,
        engine_time_after_flight: flightData?.engine_time_after_flight || 0,
        engine_time_difference: flightData?.engine_time_difference || 0,
        oil_on_inspection: flightData?.oil_on_inspection || 0,
        oil_added: flightData?.oil_added || 0,
        oil_on_departure: flightData?.oil_on_departure || 0,
        fuel_on_inspection: flightData?.fuel_on_inspection || 0,
        fuel_added: flightData?.fuel_added || 0,
        fuel_on_departure: flightData?.fuel_on_departure || 0,
        fuel_on_landing: flightData?.fuel_on_landing || 0,
        fuel_used: flightData?.fuel_used || 0,
        submittedAt: flightData?.submittedAt || '',
        uuid: flightData?.uuid || '',
        pic_user_uuid: flightData?.pic_user_uuid || '',
        remarks: flightData?.remarks || '',
    });

    const [aircrafts, setAircrafts] = useState<Aircraft[]>([]);
    const [aircraftRegistrations, setAircraftRegistration] = useState<string[]>([]);
    const [pilots, setPilots] = useState<User[]>([]);
    const [pilotQuery, setPilotQuery] = useState('');
    const [aircraftQuery, setAircraftQuery] = useState('');
    const [selectedPilot, setSelectedPilot] = useState<User | null>(null);
    const [selectedAircraft, setSelectedAircraft] = useState<Aircraft | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null); // New state for "MTH After" validation error
    const currentUserUUID = sessionStorage.getItem('user_uuid');

    // Fetching aircraft and pre-select default if no flightData (Add mode)
    useEffect(() => {
        const fetchAircraft = async () => {
            try {
                const response = await apiClient.get('/v1/getAircraft', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });
                setAircrafts(response.data);

                if (!flightData) {
                    // Add mode: check for OE-KMT as the default aircraft
                    const defaultAircraft = response.data.find((aircraft: Aircraft) => aircraft.registration === 'OE-KMT');
                    if (defaultAircraft) {
                        setSelectedAircraft(defaultAircraft);
                        setFormValues((prevValues) => ({
                            ...prevValues,
                            aircraft_registration: defaultAircraft.registration,
                        }));
                    }
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('An unknown error occurred');
                }
            }
        };
        fetchAircraft();
    }, [flightData]);

    // Fetching pilots and auto-select current user in Add mode
    useEffect(() => {
        const fetchPilots = async () => {
            try {
                const response = await apiClient.get<UsersResponse>('/getUsers', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });

                setPilots(response.data.users);

                if (!flightData) {
                    // Add mode: Automatically set the current user as Pilot in Command
                    const currentUser = response.data.users.find((user) => user.uuid === currentUserUUID);
                    if (currentUser) {
                        setSelectedPilot(currentUser);
                        setFormValues((prevValues) => ({
                            ...prevValues,
                            pilot_in_command: `${currentUser.first_name} ${currentUser.last_name}`,
                            license_number: currentUser.pilot_license_number || '',
                            pic_user_uuid: currentUser.uuid,
                        }));
                    }
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('An unknown error occurred');
                }
            }
        };
        fetchPilots();
    }, [flightData]);

    //set formFields when selectedAircraft or selectedPilot changes.
    useEffect(() => {
        const fetchFlightsForAircraft = async (registration: string) => {
            try {
                const response = await apiClient.get('/getFlights', {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                } as any);

                // Filter flights for the selected aircraft registration
                const flightsForAircraft = response.data.entries.filter((flight: Flight) => flight.aircraft_registration === registration);

                // Find the latest flight based on the submittedAt date
                const latestFlight = flightsForAircraft.reduce((latest: Flight, flight: Flight) => {
                    return new Date(latest.submittedAt) < new Date(flight.submittedAt) ? flight : latest;
                }, flightsForAircraft[0]);

                if (latestFlight) {
                    setFormValues(prevValues => {
                        // Check if the form values are not already set (e.g., when editing)
                        if (!prevValues.engine_time_before_flight && !prevValues.oil_on_inspection && !prevValues.fuel_on_inspection) {
                            return {
                                ...prevValues,
                                engine_time_before_flight: latestFlight.engine_time_after_flight || 0,
                                oil_on_inspection: latestFlight.oil_on_departure || 0,
                                fuel_on_inspection: latestFlight.fuel_on_landing || 0
                            };
                        }
                        return prevValues; // Return previous values if already set
                    });
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setError(err.message);
                    console.log(error);
                } else {
                    setError("An unknown error occurred");
                    console.log(error);
                }
            }
        };

        // Update formValues with aircraft registration when the aircraft is changed.
        if (selectedAircraft) {
            fetchFlightsForAircraft(selectedAircraft.registration);
            setFormValues(prevValues => ({
                ...prevValues,
                aircraft_registration: selectedAircraft.registration || ''
                // Add any other fields from selectedAircraft as needed
            }));
        }
        if (selectedPilot) {
            setFormValues(prevValues => ({
                ...prevValues,
                license_number: selectedPilot.pilot_license_number || '',
                pilot_in_command: `${selectedPilot.first_name} ${selectedPilot.last_name}`,
                pic_user_uuid: selectedPilot.uuid
                // Add any other fields from selectedPilot as needed
            }));
        }
    }, [selectedAircraft, selectedPilot]);

    // Engine, Oil and Fuel automatic calculations
    useEffect(() => {
        function safelyParseFloat(value: string | number): number {
            return typeof value === 'string' ? parseFloat(value) : value;
        }

        // Calculate numeric values first
        const engineTimeBefore = safelyParseFloat(formValues.engine_time_before_flight);
        const engineTimeAfter = safelyParseFloat(formValues.engine_time_after_flight);
        const oilInspection = safelyParseFloat(formValues.oil_on_inspection);
        const oilAdded = safelyParseFloat(formValues.oil_added);
        const fuelInspection = safelyParseFloat(formValues.fuel_on_inspection);
        const fuelAdded = safelyParseFloat(formValues.fuel_added);
        const fuelDeparture = safelyParseFloat(formValues.fuel_on_departure);
        const fuelLanding = safelyParseFloat(formValues.fuel_on_landing);

        let updates: Partial<Flight> = {};

        if (!isNaN(engineTimeBefore) && !isNaN(engineTimeAfter)) {
            updates.engine_time_difference = parseFloat((engineTimeAfter - engineTimeBefore).toFixed(1));
        }

        if (!isNaN(oilInspection) && !isNaN(oilAdded)) {
            updates.oil_on_departure = parseFloat((oilInspection + oilAdded).toFixed(1));
        }

        if (!isNaN(fuelInspection) && !isNaN(fuelAdded)) {
            updates.fuel_on_departure = parseFloat((fuelInspection + fuelAdded).toFixed(0));
        }

        if (!isNaN(fuelDeparture) && !isNaN(fuelLanding)) {
            updates.fuel_used = Math.max(parseFloat((fuelDeparture - fuelLanding).toFixed(1)), 0);
        }

        // Now calculate flight time
        if (formValues.departure_time && formValues.arrival_time && formValues.ground_time) {
            const [departureHours, departureMinutes] = formValues.departure_time.split(':').map(Number);
            const [arrivalHours, arrivalMinutes] = formValues.arrival_time.split(':').map(Number);
            const [groundHours, groundMinutes] = formValues.ground_time.split(':').map(Number);

            if (!isNaN(departureHours) && !isNaN(departureMinutes) && !isNaN(arrivalHours) && !isNaN(arrivalMinutes) && !isNaN(groundHours) && !isNaN(groundMinutes)) {
                const departureDate = new Date(0, 0, 0, departureHours, departureMinutes);
                const arrivalDate = new Date(0, 0, 0, arrivalHours, arrivalMinutes);
                const groundTimeTotalMinutes = groundHours * 60 + groundMinutes;

                let flightTimeMinutes = (arrivalDate.getTime() - departureDate.getTime()) / (1000 * 60) + groundTimeTotalMinutes;
                flightTimeMinutes = Math.max(flightTimeMinutes, 0);

                const flightHours = Math.floor(flightTimeMinutes / 60);
                const flightMinutes = Math.floor(flightTimeMinutes % 60);
                updates.flight_time = `${flightHours.toString().padStart(2, '0')}:${flightMinutes.toString().padStart(2, '0')}`;
            }
        }

        // Apply all updates to formValues
        if (Object.keys(updates).length > 0) {
            setFormValues(prevValues => ({
                ...prevValues,
                ...updates
            }));
        }

    }, [
        formValues.engine_time_before_flight,
        formValues.engine_time_after_flight,
        formValues.oil_on_inspection,
        formValues.oil_added,
        formValues.fuel_on_inspection,
        formValues.fuel_added,
        formValues.fuel_on_departure,
        formValues.fuel_on_landing,
        formValues.departure_time,
        formValues.arrival_time,
        formValues.ground_time,
    ]);

    // Populate form values when flightData is provided for editing
    useEffect(() => {
        if (flightData) {
            console.log(flightData);
            const [first_name, last_name] = flightData.pilot_in_command.split(' ');

            setFormValues({
                aircraft_registration: flightData.aircraft_registration || '',
                flight_date: formatDateForInput(flightData.flight_date) || today,
                departure_airfield: flightData.departure_airfield || '',
                arrival_airfield: flightData.arrival_airfield || '',
                pilot_in_command: flightData.pilot_in_command || '',
                license_number: flightData.license_number || '',
                departure_time: flightData.departure_time || '',
                arrival_time: flightData.arrival_time || '',
                flight_time: flightData.flight_time || '',
                ground_time: flightData.ground_time || '',
                number_of_landings: flightData.number_of_landings || 0,
                engine_time_before_flight: flightData.engine_time_before_flight || 0,
                engine_time_after_flight: flightData.engine_time_after_flight || 0,
                engine_time_difference: flightData.engine_time_difference || 0,
                oil_on_inspection: flightData.oil_on_inspection || 0,
                oil_added: flightData.oil_added || 0,
                oil_on_departure: flightData.oil_on_departure || 0,
                fuel_on_inspection: flightData.fuel_on_inspection || 0,
                fuel_added: flightData.fuel_added || 0,
                fuel_on_departure: flightData.fuel_on_departure || 0,
                fuel_on_landing: flightData.fuel_on_landing || 0,
                fuel_used: flightData.fuel_used || 0,
                submittedAt: flightData.submittedAt || '',
                uuid: flightData.uuid || '',
                pic_user_uuid: flightData.pic_user_uuid || '',
                remarks: flightData.remarks || ''
            });
            setSelectedPilot(prevPilot => {
                // Provide default values for all fields to ensure compatibility with the User.ts type
                return {
                    ...prevPilot, // Spread existing properties
                    username: prevPilot?.username ?? '',
                    email: prevPilot?.email ?? '',
                    role: prevPilot?.role ?? '',
                    imageUrl: prevPilot?.imageUrl ?? '',
                    first_name: first_name || (prevPilot?.first_name ?? ''), // Corrected operator usage
                    last_name: last_name || (prevPilot?.last_name ?? ''), // Corrected operator usage
                    pilot_license_number: flightData.license_number || '',
                    uuid: flightData.pic_user_uuid || ''
                };
            });
            setSelectedAircraft(selectedAircraft => {
                return {
                    uuid: selectedAircraft?.uuid || '', // Default value if prevAircraft is null or uuid is undefined
                    registration: flightData.aircraft_registration || '',
                    manufacturer: selectedAircraft?.manufacturer || '',
                    model: selectedAircraft?.model || '',
                    mthCounter: selectedAircraft?.mthCounter || 0,
                    components: selectedAircraft?.components || []
                    // Ensure all other required properties of Aircraft are included with default values
                };
            });
        }
    }, [flightData]);

    // function called when the user input changes in the field to update the form fields
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        const floatValueFields = [
            "engine_time_before_flight",
            "engine_time_after_flight",
            "engine_time_difference",
            "oil_on_inspection",
            "oil_added",
            "oil_on_departure",
            "fuel_on_inspection",
            "fuel_added",
            "fuel_on_departure",
            "fuel_on_landing",
            "fuel_used",
            "number_of_landings" // Assuming this should also be a number
        ];

        // Check if the field name is one that should be a float, and convert the value if so.
        const isFloatValueField = floatValueFields.includes(name);

        let newValue: string | number = value;
        if (isFloatValueField && value !== '') {
            newValue = parseFloat(value);
        }

        // Update state with the new value
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: newValue
        }));
    };

    // Validation logic for MTH After being less than MTH Before
    const validateEngineTime = (): boolean => {
        const {engine_time_before_flight, engine_time_after_flight} = formValues;
        if (engine_time_after_flight < engine_time_before_flight) {
            setValidationError("MTH After cannot be less than MTH Before.");
            return false;
        }
        setValidationError(null); // Clear validation error if no issues
        return true;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!validateEngineTime()) {
            return; // Do not submit if validation fails
        }

        // Clone the formValues to avoid mutating the state directly
        const submissionValues = {...formValues};

        // Adjust the date format using toISOString() if it's a valid date
        if (submissionValues.flight_date) {
            // Check if flight_date is a valid date
            const date = new Date(submissionValues.flight_date);
            if (!isNaN(date.getTime())) {
                // Valid date, format it to ISO string
                submissionValues.flight_date = date.toISOString();
            } else {
                // Handle invalid date
                setError("Invalid flight date");
                return;
            }
        }

        // Add UUID to the uuid formValue if it doesn't already have one
        if (!submissionValues.uuid && !flightData) {
            submissionValues.uuid = uuidv4();
        }

        // Specify the submittedAt field with the current date and time in ISO format only if we are adding a new flight
        if (!flightData) {
            submissionValues.submittedAt = new Date().toISOString();
        }

        const token = sessionStorage.getItem('token');
        try {
            const response = await apiClient.post('/v1/addFlight', submissionValues, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log(response.status)

            if (response.status === 200) {
                navigate('/dashboard/flights');
            } else {
                setError("Failed to add flight. Please try again.");
            }
        } catch (error: unknown) {
            setError("An error occurred while adding the flight.");
            console.error('An error occurred during the API call:', error);
        }
    };

    const handleCancel = () => {
        navigate('/dashboard/flights');
    };

    const filteredPilots = pilotQuery === ''
        ? pilots
        : pilots.filter((pilot) => {
            return pilot.first_name.toLowerCase().includes(pilotQuery.toLowerCase()) ||
                pilot.last_name.toLowerCase().includes(pilotQuery.toLowerCase());
        });

    const filteredAircraft =
        aircraftQuery === ''
            ? aircrafts
            : aircrafts.filter((aircraft) => {
                return aircraft.registration.toLowerCase().includes(aircraftQuery.toLowerCase())
            });

    return (
        <main>
            <div className="space-y-10 divide-y divide-gray-900/10">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-4">
                        <div className="px-4 sm:px-0">
                            <Subheading>General</Subheading>
                            <Text>
                                Aircraft, Pilot in Command, Route
                            </Text>
                        </div>
                        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                            <div className="px-4 py-4 sm:px-8 sm:py-8">
                                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                                    <div className="sm:col-span-2">
                                        <Combobox as="div" value={selectedPilot} onChange={(pilot) => {
                                            setSelectedPilot(pilot);
                                        }}>
                                            <Combobox.Label
                                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">Pilot
                                                in Command</Combobox.Label>
                                            <div className="relative mt-2">
                                                <Combobox.Input
                                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                                    onChange={(event) => setPilotQuery(event.target.value)}
                                                    displayValue={(pilot: User | null) => pilot ? `${pilot.first_name} ${pilot.last_name}` : ''}
                                                />

                                                <Combobox.Button
                                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400"
                                                                       aria-hidden="true"/>
                                                </Combobox.Button>

                                                {filteredPilots.length > 0 && (
                                                    <Combobox.Options
                                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600">
                                                        {filteredPilots.map((pilot) => (
                                                            <Combobox.Option
                                                                key={pilot.uuid}
                                                                value={pilot}
                                                                className={({active}) =>
                                                                    classNames(
                                                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                                                        active ? 'bg-indigo-600 text-white dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600' : 'text-gray-900 dark:text-gray-100'
                                                                    )
                                                                }
                                                            >
                                                                {({active, selected}) => (
                                                                    <>
                                                                            <span
                                                                                className={classNames('block truncate', selected ? 'font-semibold' : '')}>{pilot.first_name} {pilot.last_name}</span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                                    active ? 'text-white' : 'text-indigo-600 dark:text-gray-100'
                                                                                )}
                                                                            >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </Combobox.Options>
                                                )}
                                            </div>
                                        </Combobox>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            License Number
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="license_number"
                                                id="license_number"
                                                autoComplete="license_number"
                                                defaultValue={selectedPilot?.pilot_license_number || ''}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <Combobox as="div" value={selectedAircraft} onChange={setSelectedAircraft}>
                                            <Combobox.Label
                                                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                                Aircraft Registration
                                            </Combobox.Label>
                                            <div className="relative mt-2">
                                                <Combobox.Input
                                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900
                                   shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset
                                   focus:ring-indigo-600 sm:text-sm sm:leading-6
                                   dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                                    onChange={(event) => setAircraftQuery(event.target.value)}
                                                    displayValue={(aircraft: Aircraft | null) => aircraft?.registration ?? ''}
                                                    placeholder="Select or search aircraft registration..."
                                                />
                                                <Combobox.Button
                                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                    <ChevronUpDownIcon
                                                        className="h-5 w-5 text-gray-400 dark:text-gray-300"
                                                        aria-hidden="true"/>
                                                </Combobox.Button>

                                                {filteredAircraft.length > 0 && (
                                                    <Combobox.Options
                                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white
                                       py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                                       sm:text-sm
                                       dark:bg-zinc-800 dark:text-gray-100 dark:ring-zinc-700"
                                                    >
                                                        {filteredAircraft.map((aircraft) => (
                                                            <Combobox.Option
                                                                key={aircraft.uuid}
                                                                value={aircraft}
                                                                className={({active}) =>
                                                                    classNames(
                                                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                                                        active
                                                                            ? 'bg-indigo-600 text-white dark:bg-zinc-700 dark:text-gray-100'
                                                                            : 'text-gray-900 dark:text-gray-100'
                                                                    )
                                                                }
                                                            >
                                                                {({active, selected}) => (
                                                                    <>
                                            <span
                                                className={classNames(
                                                    'block truncate',
                                                    selected ? 'font-semibold' : ''
                                                )}
                                            >
                                                {aircraft.registration}
                                            </span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                                    active
                                                                                        ? 'text-white dark:text-gray-100'
                                                                                        : 'text-indigo-600 dark:text-zinc-700'
                                                                                )}
                                                                            >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </Combobox.Options>
                                                )}
                                            </div>
                                        </Combobox>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="flight_date"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Date
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="date"
                                                name="flight_date"
                                                id="flight_date"
                                                autoComplete="flight_date"
                                                defaultValue={formValues?.flight_date || ''}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                                placeholder="Select Flight Date"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="departure_airfield"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Departure
                                        </label>
                                        <div className="mt-2 flex rounded-md shadow-sm">
                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                <input
                                                    type="text"
                                                    name="departure_airfield"
                                                    id="departure_airfield"
                                                    autoComplete="departure_airfield"
                                                    value={formValues.departure_airfield}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900
                           ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                                    placeholder="ICAO Airport Code"
                                                />
                                            </div>
                                            <input
                                                type="time"
                                                name="departure_time"
                                                id="departure_time"
                                                autoComplete="departure_time"
                                                value={formValues.departure_time}
                                                onChange={handleChange}
                                                className="relative -ml-px flex-shrink-0 inline-flex items-center border-0
                       rounded-r-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300
                       hover:bg-gray-50
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:hover:bg-zinc-700"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="arrival_airfield"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Arrival
                                        </label>
                                        <div className="mt-2 flex rounded-md shadow-sm">
                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                <input
                                                    type="text"
                                                    name="arrival_airfield"
                                                    id="arrival_airfield"
                                                    autoComplete="arrival_airfield"
                                                    value={formValues.arrival_airfield}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900
                           ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                                    placeholder="ICAO Airport Code"
                                                />
                                            </div>
                                            <input
                                                type="time"
                                                name="arrival_time"
                                                id="arrival_time"
                                                autoComplete="arrival_time"
                                                value={formValues.arrival_time}
                                                onChange={handleChange}
                                                className="relative -ml-px flex-shrink-0 inline-flex items-center border-0
                       rounded-r-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300
                       hover:bg-gray-50
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:hover:bg-zinc-700"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="ground_time"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Ground Time (min)
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="time"
                                                name="ground_time"
                                                id="ground_time"
                                                autoComplete="ground_time"
                                                value={formValues.ground_time}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                                placeholder="Select Ground Time"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="flight_time"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Flight Time
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="time"
                                                name="flight_time"
                                                id="flight_time"
                                                autoComplete="flight_time"
                                                value={formValues.flight_time}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                                placeholder="Select Flight Time"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="number_of_landings"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Number of Landings
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="number_of_landings"
                                                id="number_of_landings"
                                                autoComplete="number_of_landings"
                                                value={formValues.number_of_landings}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                                placeholder="Enter Number of Landings"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-4">
                        <div className="px-4 sm:px-0">
                            <Subheading>Engine, Oil, Fuel
                            </Subheading>
                            <Text>State of engine, oil and fuel on
                                inspection, added, takeoff and landing
                            </Text>
                        </div>
                        <div
                            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                            <div className="px-4 py-4 sm:px-8 sm:pt-8 sm:pb-4">
                                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    {/* MTH Before */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="engine_time_before_flight"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            MTH Before
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="engine_time_before_flight"
                                                id="engine_time_before_flight"
                                                autoComplete="engine_time_before_flight"
                                                step="0.1"
                                                value={formValues.engine_time_before_flight}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                           shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                                placeholder="Enter MTH Before"
                                            />
                                        </div>
                                    </div>

                                    {/* MTH After */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="engine_time_after_flight"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            MTH After
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="engine_time_after_flight"
                                                id="engine_time_after_flight"
                                                autoComplete="engine_time_after_flight"
                                                step="0.1"
                                                value={formValues.engine_time_after_flight}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                           shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                                placeholder="Enter MTH After"
                                            />
                                            {validationError && (
                                                <div className="rounded-md bg-red-50 p-4 mt-4 dark:bg-red-800">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <ExclamationTriangleIcon aria-hidden="true"
                                                                                     className="h-5 w-5 text-red-400 dark:text-red-300"/>
                                                        </div>
                                                        <div className="ml-3">
                                                            <h3 className="text-sm font-medium text-red-800 dark:text-red-200">{validationError}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* MTH Difference */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="engine_time_difference"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            MTH Difference
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="engine_time_difference"
                                                id="engine_time_difference"
                                                autoComplete="engine_time_difference"
                                                step="0.1"
                                                disabled
                                                value={formValues.engine_time_difference}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                           shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                                placeholder="MTH Difference"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-4 sm:px-8 sm:py-4">
                                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                    {/* Oil on Inspection */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="oil_on_inspection"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Oil on Inspection
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="oil_on_inspection"
                                                id="oil_on_inspection"
                                                autoComplete="oil_on_inspection"
                                                step="0.1"
                                                value={formValues.oil_on_inspection}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                                placeholder="Enter Oil on Inspection"
                                            />
                                        </div>
                                    </div>

                                    {/* Oil Added */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="oil_added"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Oil Added
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="oil_added"
                                                id="oil_added"
                                                autoComplete="oil_added"
                                                step="0.1"
                                                value={formValues.oil_added}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                                placeholder="Enter Oil Added"
                                            />
                                        </div>
                                    </div>

                                    {/* Oil on Departure */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="oil_on_departure"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Oil on Departure
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="oil_on_departure"
                                                id="oil_on_departure"
                                                autoComplete="oil_on_departure"
                                                step="0.1"
                                                disabled
                                                value={formValues.oil_on_departure}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500 cursor-not-allowed bg-gray-50 dark:bg-zinc-800"
                                                placeholder="Oil on Departure"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 py-4 sm:px-8 sm:pt-4 sm:pb-8">
                                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                    {/* Fuel On Inspection */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="fuel_on_inspection"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Fuel On Inspection
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="fuel_on_inspection"
                                                id="fuel_on_inspection"
                                                autoComplete="fuel_on_inspection"
                                                value={formValues.fuel_on_inspection}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                                placeholder="Enter Fuel On Inspection"
                                            />
                                        </div>
                                    </div>

                                    {/* Fuel Added */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="fuel_added"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Fuel Added
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="fuel_added"
                                                id="fuel_added"
                                                autoComplete="fuel_added"
                                                value={formValues.fuel_added}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                                placeholder="Enter Fuel Added"
                                            />
                                        </div>
                                    </div>

                                    {/* Fuel on Departure */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="fuel_on_departure"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Fuel on Departure
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="fuel_on_departure"
                                                id="fuel_on_departure"
                                                autoComplete="fuel_on_departure"
                                                step="0.1"
                                                disabled
                                                value={formValues.fuel_on_departure}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500 cursor-not-allowed bg-gray-50 dark:bg-zinc-800"
                                                placeholder="Fuel on Departure"
                                            />
                                        </div>
                                    </div>

                                    {/* Fuel on Landing */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="fuel_on_landing"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Fuel on Landing
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="fuel_on_landing"
                                                id="fuel_on_landing"
                                                autoComplete="fuel_on_landing"
                                                value={formValues.fuel_on_landing}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                                placeholder="Enter Fuel on Landing"
                                            />
                                        </div>
                                    </div>

                                    {/* Fuel Used */}
                                    <div className="sm:col-span-2">
                                        <label htmlFor="fuel_used"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Fuel Used
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="fuel_used"
                                                id="fuel_used"
                                                autoComplete="fuel_used"
                                                disabled
                                                value={formValues.fuel_used}
                                                onChange={handleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500 cursor-not-allowed bg-gray-50 dark:bg-zinc-800"
                                                placeholder="Fuel Used"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-4">
                        <div className="px-4 sm:px-0">
                            <Subheading>Extra information</Subheading>
                        </div>
                        <div
                            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                            <div className="px-4 py-4 sm:px-8 sm:py-8">
                                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                                    <div className="sm:col-span-2">
                                        <label htmlFor="comment"
                                               className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                            Remarks
                                        </label>
                                        <div className="mt-2 ">
        <textarea
            rows={3}
            name="remarks"
            id="remarks"
            value={formValues.remarks}
            onChange={handleChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-zinc-900 dark:ring-zinc-600 dark:text-gray-100"
            defaultValue={''}
        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6 gap-y-8 p-10">
                        <Button
                            type="button"
                            onClick={handleCancel}
                            color="white">
                            Cancel
                        </Button>
                        <Button
                            type="submit"

                        >
                            Save
                        </Button>
                    </div>
                </form>
            </div>

        </main>
    )
};
export default AddFlightSection;