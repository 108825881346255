import React, {useState} from 'react';
import {useUsers} from '../../hooks/useUsers';
import UserCard from './UserCard';
import {Heading} from "../../catalystui/heading";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../catalystui/table";
import {Avatar} from "../../catalystui/avatar";
import {Badge} from "../../catalystui/badge";
import md5 from "md5";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "../../catalystui/dropdown";
import {EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {Input} from "../../catalystui/input";

const UserListSection: React.FC = () => {
    const {users, error, loading} = useUsers();
    const [searchTerm, setSearchTerm] = useState('');

    // Remove duplicates based on 'uuid'
    const uniqueUsers = Array.from(
        new Map(users.map((user) => [user.uuid, user])).values()
    );

    // Filter users
    const filteredUsers = uniqueUsers.filter((user) => {
        const search = searchTerm.toLowerCase();
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        const email = user.email.toLowerCase();
        const license = user.pilot_license_number?.toLowerCase() ?? '';

        return (
            fullName.includes(search) ||
            email.includes(search) ||
            license.includes(search)
        );
    });

    const getGravatarUrl = (email: string) => {
        const hash = md5(email.trim().toLowerCase());
        return `https://www.gravatar.com/avatar/${hash}?d=mp`;
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center py-10">
                <span className="text-gray-500">Loading users...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col justify-center items-center py-10">
                <span className="text-red-500 mb-4">{error}</span>
            </div>
        );
    }

    return (
        <main>
            {/* Header */}
            <div>
                <div className="lg:flex pb-6 lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <Heading>
                            Users
                        </Heading>
                    </div>
                    {/* Search Bar */}
                    <div className="mt-4 lg:mt-0">
                        <Input placeholder="Search users..." onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm}/>
                    </div>
                </div>

                <Table className="[--gutter:theme(spacing.4)] sm:[--gutter:theme(spacing.6)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Role</TableHeader>
                            <TableHeader></TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => (
                            <TableRow key={user.uuid}>
                                <TableCell>
                                    <div className="flex items-center gap-4">
                                        <Avatar src={getGravatarUrl(user.email)} className="size-12"/>
                                        <div>
                                            <div className="font-medium">{user.first_name} {user.last_name}</div>
                                            <div className="text-zinc-500">
                                                {user.pilot_license_number}
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {user.role === 'admin' ? <Badge color="lime">{user.role}</Badge> :
                                        <Badge color="zinc">{user.role}</Badge>}
                                </TableCell>
                                <TableCell>
                                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                        <Dropdown>
                                            <DropdownButton plain aria-label="More options">
                                                <EllipsisHorizontalIcon/>
                                            </DropdownButton>
                                            <DropdownMenu anchor="bottom end">
                                                <DropdownItem>
                                                    <a href={`tel:${user.phoneNumber}`}
                                                       className="text-gray-700 hover:text-indigo-600">
                                                        Call
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a href={`mailto:${user.email}`}
                                                       className="text-gray-700 hover:text-indigo-600">
                                                        Email
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a href="" className="text-gray-700 hover:text-indigo-600">
                                                        Edit
                                                    </a>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </main>
    );
};

export default UserListSection;
