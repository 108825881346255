// src/components/Forms/CRSForm.tsx

import React, {useEffect, useState} from 'react';
import {Aircraft, CertificateOfReleaseToService} from '../../../types';
import {Input} from '../../../catalystui/input';
import {Textarea} from "../../../catalystui/textarea";
import {Button} from "../../../catalystui/button";
import {Description, Field, FieldGroup, Fieldset, Label, Legend} from '../../../catalystui/fieldset';
import {Subheading} from "../../../catalystui/heading";
import {Select} from "../../../catalystui/select";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../catalystui/dialog";

interface CRSFormProps {
    onSubmit: (data: CertificateOfReleaseToService) => void;
    aircraft: Aircraft;
    isOpen: boolean;
    onClose: () => void;
}

const CRSForm: React.FC<CRSFormProps> = ({onSubmit, aircraft, isOpen, onClose}) => {
    const [crsData, setCrsData] = useState<CertificateOfReleaseToService>({
        uuid: '',
        documentType: 'Certificate of Release to Service',
        documentNumber: '',
        customer: 'MG Invest sp. z o.o.',
        aircraftType: aircraft.manufacturer,
        aircraftModel: aircraft.model,
        aircraftRegistration: aircraft.registration,
        aircraftSerialNumber: aircraft.components?.find((component) => component.title === 'Airframe')?.serialNumber || '',
        airframeHours: aircraft.components?.find((component) => component.title === 'Airframe')?.currentHours || 0,
        mthCounter: aircraft.mthCounter || 0,
        aircraftCycleCounter: 'N/A',
        engineModel: aircraft.components?.find((component) => component.title === 'Engine')?.partNumber || '',
        engineSerialNumber: aircraft.components?.find((component) => component.title === 'Engine')?.serialNumber || '',
        engineTimeSinceOverhaul: aircraft.components?.find((component) => component.title === 'Engine')?.currentHours || 0,
        propellerModel: aircraft.components?.find((component) => component.title === 'Propeller')?.partNumber || '',
        propellerSerialNumber: aircraft.components?.find((component) => component.title === 'Propeller')?.serialNumber || '',
        propellerTimeSinceOverhaul: aircraft.components?.find((component) => component.title === 'Propeller')?.currentHours || 0,
        maintenance: {
            started: '',
            location: '',
            finished: '',
            referenceDocuments: [],
        },
        maintenanceTasksPerformed: [],
        partsRemoved: [],
        partsInstalled: [],
        workOrder: '',
        releaseToService: {
            date: '',
            certifyingStaff: '',
            certifyingAuthority: '',
            signature: '',
        },
        linkedMaintenanceTasks: [],
        adHocTasks: [],
    });
    const [isAircraftDataEditMode, setAircraftDataEditMode] = useState(false);
    useEffect(() => {
        const delta = crsData.mthCounter - (aircraft.mthCounter || 0);
        setCrsData(prevData => ({
            ...prevData,
            airframeHours: Number(((aircraft.components?.find(comp => comp.title === 'Airframe')?.currentHours || 0) + delta).toFixed(1)),
            engineTimeSinceOverhaul: Number(((aircraft.components?.find(comp => comp.title === 'Engine')?.currentHours || 0) + delta).toFixed(1)),
            propellerTimeSinceOverhaul: Number(((aircraft.components?.find(comp => comp.title === 'Propeller')?.currentHours || 0) + delta).toFixed(1)),
        }));
    }, [crsData.mthCounter, aircraft.mthCounter, aircraft.components]);
    const handleChange = (field: string, value: any) => {
        setCrsData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };
    const handleLinkedTaskChange = (index: number, field: string, value: any) => {
        setCrsData((prevData) => {
            const updatedLinkedTasks = [...prevData.linkedMaintenanceTasks];
            updatedLinkedTasks[index] = {...updatedLinkedTasks[index], [field]: value};
            return {
                ...prevData,
                linkedMaintenanceTasks: updatedLinkedTasks,
            };
        });
    };

    const handleAddLinkedTask = () => {
        setCrsData((prevData) => ({
            ...prevData,
            linkedMaintenanceTasks: [
                ...prevData.linkedMaintenanceTasks,
                {
                    componentUuid: '',
                    scheduleItemUuid: '',
                    taskDescription: '',
                    performedAtHours: 0,
                    performedDate: '',
                    comment: '',
                },
            ],
        }));
    };
    const handleSave = () => {
        onSubmit(crsData); // Pass crsData back to parent
    };

    return (
        <Dialog size="5xl" open={isOpen} onClose={onClose}>
            <DialogTitle>Add Certificate of Release to Service</DialogTitle>
            <DialogDescription>
                Fill out the details for the CRS to add it to the aircraft’s record.
            </DialogDescription>
            <DialogBody>
                {/* CRS Details */}
                <Fieldset>
                    <FieldGroup>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <Field>
                                <Label>Document Number</Label>
                                <Input
                                    name="documentNumber"
                                    value={crsData.documentNumber}
                                    onChange={(e) => handleChange('documentNumber', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Customer</Label>
                                <Input
                                    name="customer"
                                    value={crsData.customer}
                                    onChange={(e) => handleChange('customer', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>MTH at Maintenance</Label>
                                <Input
                                    name="mthCounter"
                                    value={crsData.mthCounter}
                                    onChange={(e) => handleChange('mthCounter', e.target.value)}
                                />
                            </Field>
                        </div>
                    </FieldGroup>
                </Fieldset>

                {/* Aircraft Information */}
                {isAircraftDataEditMode ? (
                    <Fieldset className="mt-4">
                        <Legend>Aircraft Information</Legend>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                <Field>
                                    <Label>Aircraft Type</Label>
                                    <Input
                                        name="aircraftType"
                                        value={crsData.aircraftType}
                                        onChange={(e) => handleChange('aircraftType', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Aircraft Model</Label>
                                    <Input
                                        name="aircraftModel"
                                        value={crsData.aircraftModel}
                                        onChange={(e) => handleChange('aircraftModel', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Registration</Label>
                                    <Input
                                        name="aircraftRegistration"
                                        value={crsData.aircraftRegistration}
                                        onChange={(e) => handleChange('aircraftRegistration', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Cycle Count</Label>
                                    <Input
                                        name="aircraftCycleCounter"
                                        value={crsData.aircraftCycleCounter}
                                        onChange={(e) => handleChange('aircraftCycleCounter', e.target.value)}
                                    />
                                </Field>
                            </div>
                        </FieldGroup>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                <Field>
                                    <Label>Airframe Model</Label>
                                    <Input
                                        name="aircraftModel"
                                        value={crsData.aircraftModel}
                                        onChange={(e) => handleChange('aircraftModel', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Airframe Serial Number</Label>
                                    <Input
                                        name="aircraftSerialNumber"
                                        value={crsData.aircraftSerialNumber}
                                        onChange={(e) => handleChange('aircraftSerialNumber', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Airframe time</Label>
                                    <Input
                                        name="airframeHours"
                                        value={crsData.airframeHours}
                                        onChange={(e) => handleChange('airframeHours', e.target.value)}
                                    />
                                </Field>
                            </div>
                        </FieldGroup>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                <Field>
                                    <Label>Engine Model</Label>
                                    <Input
                                        name="engineModel"
                                        value={crsData.engineModel}
                                        onChange={(e) => handleChange('engineModel', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Engine Serial Number</Label>
                                    <Input
                                        name="engineSerialNumber"
                                        value={crsData.engineSerialNumber}
                                        onChange={(e) => handleChange('engineSerialNumber', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Engine TSOH</Label>
                                    <Input
                                        name="engineTimeSinceOverhaul"
                                        value={crsData.engineTimeSinceOverhaul}
                                        onChange={(e) => handleChange('engineTimeSinceOverhaul', e.target.value)}
                                    />
                                </Field>
                            </div>
                        </FieldGroup>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                <Field>
                                    <Label>Propeller Model</Label>
                                    <Input
                                        name="propellerModel"
                                        value={crsData.propellerModel}
                                        onChange={(e) => handleChange('propellerModel', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Propeller Serial Number</Label>
                                    <Input
                                        name="propellerSerialNumber"
                                        value={crsData.propellerSerialNumber}
                                        onChange={(e) => handleChange('propellerSerialNumber', e.target.value)}
                                    />
                                </Field>
                                <Field>
                                    <Label>Propeller TSOH</Label>
                                    <Input
                                        name="propellerTimeSinceOverhaul"
                                        value={crsData.propellerTimeSinceOverhaul}
                                        onChange={(e) => handleChange('propellerTimeSinceOverhaul', e.target.value)}
                                    />
                                </Field>
                            </div>
                        </FieldGroup>
                    </Fieldset>
                ) : (
                    <div className="mt-4 space-y-2">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col items-end gap-2">
                                <Subheading>Aircraft Information</Subheading>
                            </div>
                            <button
                                onClick={() => setAircraftDataEditMode(true)}
                                className="text-xs text-blue-500 mt-2 hover:underline focus:outline-none"
                            >
                                Edit
                            </button>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div>
                                <div
                                    className="mt-3 text-xs font-semibold text-gray-900 dark:text-gray-100">Aircraft
                                    Type
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.aircraftType}</div>
                            </div>
                            <div>
                                <div
                                    className="mt-3 text-xs font-semibold text-gray-900 dark:text-gray-100">Aircraft
                                    Model
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.aircraftModel}</div>
                            </div>
                            <div>
                                <div
                                    className="mt-3 text-xs font-semibold text-gray-900 dark:text-gray-100">Registration
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.aircraftRegistration}</div>
                            </div>
                            <div>
                                <div className="mt-3 text-xs font-semibold text-gray-900 dark:text-gray-100">Cycle
                                    Count
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.aircraftCycleCounter}</div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div>
                                <div
                                    className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Airframe
                                    Model
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.aircraftModel}</div>
                            </div>
                            <div>
                                <div
                                    className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Airframe
                                    Serial
                                    Number
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.aircraftSerialNumber}</div>
                            </div>
                            <div>
                                <div
                                    className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Airframe
                                    Time
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.airframeHours} Hours
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div>
                                <div className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Engine
                                    Model
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.engineModel}</div>
                            </div>
                            <div>
                                <div className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Engine
                                    Serial
                                    Number
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.engineSerialNumber}</div>
                            </div>
                            <div>
                                <div className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Engine
                                    TSOH
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.engineTimeSinceOverhaul} Hours
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div>
                                <div
                                    className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Propeller
                                    Model
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.propellerModel}</div>
                            </div>
                            <div>
                                <div
                                    className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Propeller
                                    Serial Number
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.propellerSerialNumber}</div>
                            </div>
                            <div>
                                <div
                                    className="mt-2 text-xs font-semibold text-gray-900 dark:text-gray-100">Propeller
                                    TSOH
                                </div>
                                <div
                                    className="mt-1 text-xs text-gray-900 dark:text-gray-100">{crsData.propellerTimeSinceOverhaul} Hours
                                </div>
                            </div>
                        </div>
                    </div>

                )}


                {/* Maintenance Information */}
                <Fieldset className="mt-4">
                    <Legend>Maintenance Information</Legend>
                    <FieldGroup>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <Field>
                                <Label>Started</Label>
                                <Input
                                    type="datetime-local"
                                    value={crsData.maintenance.started}
                                    onChange={(e) =>
                                        handleChange('maintenance', {
                                            ...crsData.maintenance,
                                            started: e.target.value
                                        })
                                    }
                                />
                            </Field>
                            <Field>
                                <Label>Finished</Label>
                                <Input
                                    type="datetime-local"
                                    value={crsData.maintenance.finished}
                                    onChange={(e) =>
                                        handleChange('maintenance', {
                                            ...crsData.maintenance,
                                            finished: e.target.value
                                        })
                                    }
                                />
                            </Field>
                            <Field>
                                <Label>Location</Label>
                                <Input
                                    name="maintenanceLocation"
                                    value={crsData.maintenance.location}
                                    onChange={(e) =>
                                        handleChange('maintenance', {
                                            ...crsData.maintenance,
                                            location: e.target.value
                                        })
                                    }
                                />
                            </Field>
                        </div>
                    </FieldGroup>
                </Fieldset>

                <Fieldset>
                    <Legend>Linked Maintenance Tasks</Legend>
                    <Description>Select component and maintenance schedule, then fill in task details.</Description>

                    {crsData.linkedMaintenanceTasks.map((task, index) => (
                        <FieldGroup key={index} className="space-y-2">
                            <Field>
                                <Label>Select Component</Label>
                                <Select
                                    value={task.componentUuid}
                                    onChange={(e) => handleLinkedTaskChange(index, 'componentUuid', e.target.value)}
                                    className="input"
                                >
                                    <option value="" disabled>Select component</option>
                                    {aircraft.components?.map((component) => (
                                        <option key={component.uuid} value={component.uuid}>
                                            {component.title}
                                        </option>
                                    ))}
                                </Select>
                            </Field>

                            {/* Display maintenance schedules based on selected component */}
                            {task.componentUuid && (
                                <Field>
                                    <Label>Select Maintenance Schedule</Label>
                                    <Select
                                        name={`schedule-${index}`}
                                        value={task.scheduleItemUuid}
                                        onChange={(e) => handleLinkedTaskChange(index, 'scheduleItemUuid', e.target.value)}
                                    >
                                        <option value="" disabled>Select maintenance schedule</option>
                                        {aircraft.components?.find((component) => component.uuid === task.componentUuid)
                                            ?.maintenanceSchedule?.map((schedule) => (
                                                <option key={schedule.uuid} value={schedule.uuid}>
                                                    {schedule.description}
                                                </option>
                                            ))}
                                    </Select>
                                </Field>
                            )}

                            {/* Task Details */}
                            <Field>
                                <Label>Task Description</Label>
                                <Input
                                    value={task.taskDescription}
                                    onChange={(e) => handleLinkedTaskChange(index, 'taskDescription', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Performed At Hours</Label>
                                <Input
                                    type="number"
                                    value={task.performedAtHours}
                                    onChange={(e) => handleLinkedTaskChange(index, 'performedAtHours', parseFloat(e.target.value))}
                                />
                            </Field>
                            <Field>
                                <Label>Performed Date</Label>
                                <Input
                                    type="date"
                                    value={task.performedDate}
                                    onChange={(e) => handleLinkedTaskChange(index, 'performedDate', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Comment</Label>
                                <Textarea
                                    value={task.comment}
                                    onChange={(e) => handleLinkedTaskChange(index, 'comment', e.target.value)}
                                />
                            </Field>
                        </FieldGroup>
                    ))}
                    <Button className="mt-4" onClick={handleAddLinkedTask}>Add Linked Task</Button>
                </Fieldset>
            </DialogBody>
            <DialogActions>
                <Button plain onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>


    );
};

export default CRSForm;
