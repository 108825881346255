import React from 'react';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import UserListSection from "./UserListSection";
import FlightManagementSection from "./FlightManagementSection";
import FuelManagementSection from "./FuelComponents/FuelManagementSection";
import UserProfileSection from "./UserProfileSection";
import AddFlightSection from "./AddFlightSection";
import EditFlightWrapper from "./EditFlightWrapper";
import FlightPlanningSection from "./FlightPlanningComponents/FlightPlanningSection";
import {StackedLayout} from '../../catalystui/stacked-layout'
import {Navbar, NavbarItem, NavbarSection, NavbarSpacer} from "../../catalystui/navbar";
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu
} from "../../catalystui/dropdown";
import {Avatar} from "../../catalystui/avatar";
import {ArrowRightStartOnRectangleIcon, UserIcon} from "@heroicons/react/16/solid";
import {Sidebar, SidebarBody, SidebarItem, SidebarSection} from "../../catalystui/sidebar";
import md5 from "md5";
import ToggleThemeButton from "../ToggleThemeButton";
import AircraftList from "./AircraftComponents/AircraftList";
import AircraftDetailsWrapper from "./AircraftDetailsWrapper";
import ComponentDetails from "./AircraftComponents/ComponentDetails";
import AircraftIssueSection from "./AircraftComponents/AddEditViewForms/AircraftIssueSection";



const DashboardScreen: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isCurrentRoute = (href: string) => location.pathname === href;

    const navigation = [
        {name: 'Flights', href: '/dashboard/flights'},
        {name: 'Aircraft', href: '/dashboard/aircraft'},
        {name: 'Flight Planning', href: '/dashboard/flight-planning'},
        {name: 'Fuel', href: '/dashboard/fuel'},
        {name: 'Team', href: '/dashboard/team'},

    ];

    const getEmailHash = (email : string) => {
        return md5(email.trim().toLowerCase());
    };

    const userEmail = sessionStorage.getItem('email');
    const gravatarUrl = userEmail ? `https://www.gravatar.com/avatar/${getEmailHash(userEmail)}`
        : "https://www.gravatar.com/avatar/?d=identicon";

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    // Function to handle sign out
    const handleSignOut = () => {
        sessionStorage.clear(); // Clear session data
        window.location.href = '/';
    };

    return (
        <div className="min-h-full">
            <StackedLayout
                navbar={
                    <Navbar>
                        <NavbarSection className="max-lg:hidden">
                            {navigation.map(({name, href}) => (
                                <NavbarItem key={name} href={href}>
                                    {name}
                                </NavbarItem>
                            ))}
                        </NavbarSection>
                        <NavbarSpacer/>
                        <NavbarSection>
                            <NavbarItem aria-label="Toggle Theme">
                                <ToggleThemeButton/>
                            </NavbarItem>
                            <Dropdown>
                                <DropdownButton as={NavbarItem}>
                                    <Avatar src={gravatarUrl} square/>
                                </DropdownButton>
                                <DropdownMenu className="min-w-64" anchor="bottom end">
                                    <DropdownItem href="/dashboard/userprofile">
                                        <UserIcon/>
                                        <DropdownLabel>My profile</DropdownLabel>
                                    </DropdownItem>
                                    <DropdownDivider/>
                                    <DropdownItem onClick={handleSignOut}>
                                        <ArrowRightStartOnRectangleIcon/>
                                        <DropdownLabel>Sign out</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </NavbarSection>
                    </Navbar>
                }
                sidebar={
                    <Sidebar>
                        <SidebarBody>
                            <SidebarSection>
                                {navigation.map(({ name, href }) => (
                                    <SidebarItem key={name} href={href}>
                                        {name}
                                    </SidebarItem>
                                ))}
                            </SidebarSection>
                        </SidebarBody>
                    </Sidebar>
                }>
                {/*<DashboardNav/>*/}
                <Routes>
                    <Route path="flights" element={<FlightManagementSection/>}/>
                    <Route path="flights/add" element={<AddFlightSection/>}/>
                    <Route path="flights/edit/:uuid" element={<EditFlightWrapper/>}/>
                    <Route path="fuel" element={<FuelManagementSection/>}/>
                    <Route path="aircraft" element={<AircraftList/>}/>
                    <Route path="aircraft/:uuid" element={<AircraftDetailsWrapper />} />
                    <Route path="aircraft/:uuid/component/:componentUuid" element={<ComponentDetails />} />
                    <Route path="aircraft/:uuid/issues/" element={<AircraftIssueSection/>} />
                    <Route path="team" element={<UserListSection/>}/>
                    <Route path="userprofile" element={<UserProfileSection/>}/>
                    <Route path="flight-planning" element={<FlightPlanningSection/>}/>
                </Routes>
            </StackedLayout>
        </div>
    );
};

export default DashboardScreen;
