'use client'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    PlusCircleIcon,
} from '@heroicons/react/20/solid'
import {useEffect, useRef, useState} from 'react'
import apiClient from '../../../tools/AxiosInterceptor' // Adjust the import path
import {Flight} from '../../../types' // Adjust the import path
import {Button} from "../../../catalystui/button";
import {Heading, Subheading} from "../../../catalystui/heading"; // Import uuid for generating IDs

type ReservationStatus =
    | 'new'
    | 'waiting for approval'
    | 'confirmed'
    | 'scheduled'
    | 'in-progress'
    | 'completed'
    | 'cancelled'
    | 'rejected'
    | 'on-hold';
interface Reservation {
    id: string;
    date: string; // ISO 8601 date string
    plannedDepartureTime: string; // 'HH:MM' format
    plannedArrivalTime: string;   // 'HH:MM' format
    plannedMTH: number; // Motor Time Hours, e.g., 1.1
    pilotInCommand: string;
    plannedFuel: number;
    plannedDepartureAirfield: string;
    plannedArrivalAirfield: string;
    status: ReservationStatus;
    customerName: string;
    customerContact: string;
    addedBy: string;
}
type EventType = 'flight' | 'reservation';

interface FlightEvent extends Flight {
    type: 'flight';
}

interface ReservationEvent extends Reservation {
    type: 'reservation';
}

type CombinedEvent = FlightEvent | ReservationEvent;

const sampleReservation: Reservation = {
    id: 'f0c70e9f-174d-4433-b8ae-b8cdb5808ed6', // Use uuidv4() in practice
    date: '2024-10-18T00:00:00.000Z',
    plannedDepartureTime: '14:40',
    plannedArrivalTime: '16:00',
    plannedMTH: 1.1,
    pilotInCommand: 'Michał Mądry',
    plannedFuel: 45,
    plannedDepartureAirfield: 'EPNT',
    plannedArrivalAirfield: 'EPNT',
    status: 'confirmed',
    customerName: 'Kyrylo Groshev',
    customerContact: '+48888706878',
    addedBy: 'Kyrylo Groshev'
};

function classNames(...classes: (string | undefined | null | false)[]) {
    return classes.filter(Boolean).join(' ')
}

// Utility functions
const groupFlightsByMonth = (flights: Flight[]) => {
    // Group flights by month in 'YYYY-MM' format
    return flights.reduce((acc: { [key: string]: Flight[] }, flight) => {
        const month = new Date(flight.flight_date).toISOString().slice(0, 7) // 'YYYY-MM'
        if (!acc[month]) {
            acc[month] = []
        }
        acc[month].push(flight)
        return acc
    }, {})
}

const findMostRecentMonthWithFlights = (groupedFlights: { [key: string]: Flight[] }) => {
    const months = Object.keys(groupedFlights)
    // Sort months in descending order to get the most recent month first
    months.sort((a, b) => (a > b ? -1 : 1))
    return months[0] || null
}

export default function FlightPlanningComponent() {
    const [flights, setFlights] = useState<Flight[]>([])
    const [filteredFlights, setFilteredFlights] = useState<Flight[]>([])
    const [expandedMonth, setExpandedMonth] = useState<string | null>(null)
    const [reservations, setReservations] = useState<Reservation[]>([sampleReservation]);
    const [daysWithFlights, setDaysWithFlights] = useState<{ [key: string]: boolean }>({});
    const [error, setError] = useState<string | null>(null)
    const container = useRef<HTMLDivElement>(null)
    const containerNav = useRef<HTMLDivElement>(null)
    const containerOffset = useRef<HTMLDivElement>(null)

    const [currentDate, setCurrentDate] = useState(new Date()) // State for the currently displayed date
    const [days, setDays] = useState<any[]>([]) // Days to display in the right-side calendar

    const groupFlightsByDate = (flights: Flight[]) => {
        return flights.reduce((acc: { [key: string]: boolean }, flight) => {
            const date = new Date(flight.flight_date).toISOString().split('T')[0]; // Get 'YYYY-MM-DD'
            acc[date] = true; // Mark this date as having a flight
            return acc;
        }, {});
    };

    const combinedEvents: CombinedEvent[] = [
        ...filteredFlights.map((flight) => ({ ...flight, type: 'flight' as const })),
        ...reservations.map((reservation) => ({ ...reservation, type: 'reservation' as const })),
    ];

    useEffect(() => {
        console.log('Updated Days with Flights:', daysWithFlights);
    }, [daysWithFlights]);

    useEffect(() => {
        const fetchFlights = async () => {
            try {
                const response = await apiClient.get('/getFlights', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });
                setFlights(response.data.entries);
                setFilteredFlights(response.data.entries); // Initialize filtered flights
                const groupedByDate = groupFlightsByDate(response.data.entries);
                console.log("Grouped Flights by Date:", groupedByDate); // Log to check data

                setDaysWithFlights(groupedByDate); // Set the state for days with flights
                const groupedFlights = groupFlightsByMonth(response.data.entries);
                setExpandedMonth(findMostRecentMonthWithFlights(groupedFlights));
            } catch (err: any) {
                setError(err.message || 'Unable to fetch flights.');
            }
        };

        fetchFlights();
    }, []);

    useEffect(() => {
        const startOfMonth = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1))
        const endOfMonth = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))

        // Calculate start date (Sunday of the first week) in UTC
        const startDate = new Date(startOfMonth)
        startDate.setUTCDate(startDate.getUTCDate() - startDate.getUTCDay())

        // Calculate end date (Saturday of the last week) in UTC
        const endDate = new Date(endOfMonth)
        endDate.setUTCDate(endDate.getUTCDate() + (6 - endDate.getUTCDay()))

        const daysArray = []
        for (let d = new Date(startDate); d <= endDate; d.setUTCDate(d.getUTCDate() + 1)) {
            daysArray.push({
                date: new Date(d),
                isCurrentMonth: d.getUTCMonth() === currentDate.getUTCMonth(),
                isToday: isSameUTCDate(d, new Date()),
                isSelected: isSameUTCDate(d, currentDate),
            })
        }
        setDays(daysArray)
    }, [currentDate])

    // Helper function to compare dates in UTC
    function isSameUTCDate(date1: Date, date2: Date) {
        return (
            date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate()
        )
    }

    // Get the current date at midnight UTC
    const currentDateUTC = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()))

    // Define time range from 6 AM to 6 PM
    const startHour = 6
    const endHour = 18
    const minutesPerInterval = 5
    const intervalsPerHour = 60 / minutesPerInterval
    const totalIntervals = (endHour - startHour) * intervalsPerHour // Total intervals between 6 AM and 6 PM

    // Filter events based on the selected date
    const events = combinedEvents
        .filter((event) => {
            const eventDateString = event.type === 'flight' ? event.flight_date : event.date;
            const eventDate = new Date(eventDateString);
            return (
                eventDate.getUTCFullYear() === currentDateUTC.getUTCFullYear() &&
                eventDate.getUTCMonth() === currentDateUTC.getUTCMonth() &&
                eventDate.getUTCDate() === currentDateUTC.getUTCDate()
            );
        })
        .map((event) => {
            let startTimeString: string;
            let endTimeString: string;
            let eventDateString: string;
            let arrivalAirfield: string;
            let departureAirfield: string;
            let pilotInCommand: string;
            let customerName: string;
            let customerContact: string;
            let addedBy: string;

            if (event.type === 'flight') {
                // event is FlightEvent
                startTimeString = event.departure_time;
                endTimeString = event.arrival_time;
                eventDateString = event.flight_date.split('T')[0];
                arrivalAirfield = event.arrival_airfield;
                departureAirfield = event.departure_airfield;
                pilotInCommand = event.pilot_in_command;
            } else {
                // event is ReservationEvent
                startTimeString = event.plannedDepartureTime;
                endTimeString = event.plannedArrivalTime;
                eventDateString = event.date.split('T')[0];
                arrivalAirfield = event.plannedArrivalAirfield;
                departureAirfield = event.plannedDepartureAirfield;
                pilotInCommand = event.pilotInCommand;
                customerName = event.customerName;
                customerContact = event.customerContact;
                addedBy = event.addedBy;
            }

            const startTime = new Date(`${eventDateString}T${startTimeString}`);
            const endTime = new Date(`${eventDateString}T${endTimeString}`);

            const startMinutesTotal = startTime.getHours() * 60 + startTime.getMinutes();
            const endMinutesTotal = endTime.getHours() * 60 + endTime.getMinutes();

            // Adjust times to fit within the 6 AM - 6 PM window
            const startMinutesAdjusted = Math.max(startMinutesTotal, startHour * 60);
            const endMinutesAdjusted = Math.min(endMinutesTotal, endHour * 60);

            // Only include events that occur within the time frame
            if (endMinutesAdjusted <= startMinutesAdjusted) {
                return null; // Exclude events outside the time frame
            }

            const startInterval = Math.floor(
                (startMinutesAdjusted - startHour * 60 + minutesPerInterval / 2) / minutesPerInterval
            );
            const endInterval = Math.floor(
                (endMinutesAdjusted - startHour * 60 + minutesPerInterval / 2) / minutesPerInterval
            );

            const startGridRow = startInterval + 1; // Grid rows start at 1
            const span = Math.max(endInterval - startInterval, 1);

            return {
                ...event,
                startGridRow,
                span,
                startTime,
                endTime,
                arrivalAirfield,
                departureAirfield,
                pilotInCommand,
            };
        })
        .filter((event) => event !== null) as any[]; // Filter out null events

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <div className="flex h-full flex-col bg-white dark:bg-zinc-900 text-gray-900 dark:text-gray-100">
            {/* Header */}
            <header
                className="flex flex-none items-center justify-between border-b border-gray-200 dark:border-zinc-700 pb-6">
                <div>
                    <Heading>
                        <time dateTime={currentDate.toISOString().split('T')[0]} className="sm:hidden">
                            {currentDate.toLocaleDateString('pl-PL', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </time>
                        <time dateTime={currentDate.toISOString().split('T')[0]} className="hidden sm:inline">
                            {currentDate.toLocaleDateString('pl-PL', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </time>
                    </Heading>
                    <Subheading>{currentDate.toLocaleDateString('pl-PL', {weekday: 'long'})}</Subheading>
                </div>
                <div className="flex items-center">
                    <div
                        className="relative flex items-center rounded-md bg-white dark:bg-zinc-700 shadow-sm md:items-stretch">
                        {/* Navigation Buttons */}
                        <button
                            type="button"
                            onClick={() =>
                                setCurrentDate(
                                    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1)
                                )
                            }
                            className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 dark:border-zinc-600 pr-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50 dark:md:hover:bg-zinc-700"
                        >
                            <span className="sr-only">Previous day</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            onClick={() => setCurrentDate(new Date())}
                            className="hidden border-y border-gray-300 dark:border-zinc-600 px-3.5 text-sm font-semibold text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-zinc-700 focus:relative md:block"
                        >
                            Today
                        </button>
                        <span className="relative -mx-px h-5 w-px bg-gray-300 dark:bg-zinc-600 md:hidden"/>
                        <button
                            type="button"
                            onClick={() =>
                                setCurrentDate(
                                    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1)
                                )
                            }
                            className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 dark:border-zinc-600 pl-1 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50 dark:md:hover:bg-zinc-700"
                        >
                            <span className="sr-only">Next day</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="ml-6 mr-6 h-6 w-px bg-gray-300 dark:bg-zinc-600"/>
                    <Button>
                        <PlusCircleIcon/>
                        Add Reservation
                    </Button>
                </div>
            </header>

            {/* Main Content */}
            <div className="isolate flex flex-auto overflow-hidden bg-white dark:bg-zinc-800">
                <div ref={container} className="flex flex-auto flex-col overflow-auto">
                    <div
                        ref={containerNav}
                        className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white dark:bg-zinc-800 text-xs text-gray-500 dark:text-gray-400 shadow ring-1 ring-black ring-opacity-5 md:hidden"
                    >
                        {/* Mobile navigation for days */}
                    </div>
                    <div className="flex w-full flex-auto">
                        <div
                            className="w-14 flex-none bg-white dark:bg-zinc-800 ring-1 ring-gray-100 dark:ring-zinc-700"/>
                        <div className="grid flex-auto grid-cols-1 grid-rows-1">
                            {/* Horizontal lines */}
                            <div
                                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-zinc-700"
                                style={{gridTemplateRows: `repeat(${totalIntervals}, minmax(1rem, 1fr))`}}
                            >
                                <div ref={containerOffset} className="row-end-1 h-7"></div>
                                {[...Array(endHour - startHour + 1)].map((_, index) => {
                                    const hour = startHour + index;
                                    const gridRowPosition = index * intervalsPerHour + 1;
                                    if (hour > endHour) return null;

                                    return (
                                        <div key={hour} style={{gridRow: `${gridRowPosition}`}}>
                                            <div
                                                className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400 dark:text-gray-200">
                                                {`${hour}:00`}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/* Events */}
                            <ol
                                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                                style={{
                                    gridTemplateRows: `repeat(${totalIntervals}, minmax(1rem, 1fr))`,
                                    gridTemplateColumns: `1fr 1fr`, // Two columns: flights and reservations
                                }}
                            >
                                {events.map((event) => {
                                    const formattedDepartureTime = event.startTime.toLocaleTimeString('pl-PL', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    });
                                    const formattedArrivalTime = event.endTime.toLocaleTimeString('pl-PL', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    });

                                    const backgroundColor =
                                        event.type === 'flight'
                                            ? 'bg-blue-50 dark:bg-blue-900 hover:bg-blue-100 dark:hover:bg-blue-800'
                                            : 'bg-yellow-50 dark:bg-yellow-900 hover:bg-yellow-100 dark:hover:bg-yellow-800';

                                    const textColor =
                                        event.type === 'flight' ? 'text-blue-700 dark:text-blue-200' : 'text-yellow-700 dark:text-yellow-200';

                                    const secondaryTextColor =
                                        event.type === 'flight'
                                            ? 'text-blue-500 dark:text-blue-300 group-hover:text-blue-700 dark:group-hover:text-blue-200'
                                            : 'text-yellow-500 dark:text-yellow-300 group-hover:text-yellow-700 dark:group-hover:text-yellow-200';

                                    return (
                                        <li
                                            key={event.uuid || event.id}
                                            className="relative mt-px flex"
                                            style={{
                                                gridRow: `${event.startGridRow} / span ${event.span}`,
                                                gridColumn: event.type === 'flight' ? '1' : '2',
                                            }}
                                        >
                                            <a
                                                href="#"
                                                className={`group absolute inset-1 flex flex-col overflow-y-auto rounded-lg ${backgroundColor} p-2 text-xs leading-5`}
                                            >
                                                {event.type === 'flight' ? (
                                                    <>
                                                        <p className={`font-semibold ${secondaryTextColor}`}>
                                                            <span>{event.departure_airfield} </span>
                                                            <time dateTime={event.startTime.toISOString()}>
                                                                {formattedDepartureTime}
                                                            </time>
                                                            {' '}
                                                            →{' '}
                                                            <span>{event.arrivalAirfield} </span>
                                                            <time dateTime={event.endTime.toISOString()}>
                                                                {formattedArrivalTime}
                                                            </time>
                                                            {' '}
                                                            <span>(Flight time {event.flight_time})</span>
                                                        </p>
                                                        <p className={` ${secondaryTextColor}`}>
                                                            Pilot: {event.pilotInCommand}
                                                        </p>
                                                        <p className={`${secondaryTextColor}`}>
                                                            <span>MTH: {event.engine_time_difference},
                                                                Fuel: {event.fuel_used}</span>
                                                        </p>
                                                        {event.remarks && (
                                                            <p className={`${secondaryTextColor}`}>
                                                                Remarks: {event.remarks}
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className={`order-1 font-semibold ${textColor}`}>
                                                            Reservation to {event.arrivalAirfield}
                                                        </p>
                                                        <p className={`order-1 font-semibold ${textColor}`}>
                                                            Customer Name: {event.customerName}
                                                        </p>
                                                        <p className={`order-1 ${textColor}`}>
                                                            Customer Contact: {event.customerContact}
                                                        </p>
                                                        <p className={`order-1 ${secondaryTextColor}`}>
                                                            {`${event.departureAirfield} → ${event.arrivalAirfield}`}
                                                        </p>
                                                        <p className={`${secondaryTextColor}`}>
                                                            Pilot: {event.pilotInCommand}
                                                        </p>
                                                        <p className={`${secondaryTextColor}`}>
                                                            Planned MTH: {event.plannedMTH}
                                                        </p>
                                                        <p className={`${secondaryTextColor}`}>
                                                            Planned Fuel: {event.plannedFuel}
                                                        </p>
                                                        <p className={`${secondaryTextColor}`}>
                                                            <time dateTime={event.startTime.toISOString()}>
                                                                {formattedDepartureTime}
                                                            </time>
                                                            {' '}
                                                            -{' '}
                                                            <time dateTime={event.endTime.toISOString()}>
                                                                {formattedArrivalTime}
                                                            </time>
                                                        </p>
                                                        <p className={`${secondaryTextColor}`}>
                                                            Status: {event.status}
                                                        </p>
                                                    </>
                                                )}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
                <div
                    className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 dark:border-zinc-700 px-8 py-10 md:block">
                    {/* Month navigation */}
                    <div className="flex items-center text-center">
                        <button
                            type="button"
                            onClick={() =>
                                setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
                            }
                            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-100"
                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                        <div className="flex-auto text-sm font-semibold">
                            {currentDate.toLocaleDateString('pl-PL', {month: 'long', year: 'numeric'})}
                        </div>
                        <button
                            type="button"
                            onClick={() =>
                                setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
                            }
                            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-100"
                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                    <div
                        className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500 dark:text-gray-400">
                        {['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'].map((day) => (
                            <div key={day}>{day}</div>
                        ))}
                    </div>
                    <div
                        className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 dark:bg-zinc-700 text-sm shadow ring-1 ring-gray-200 dark:ring-zinc-600">
                        {days.map((day, dayIdx) => {
                            const dayString = day.date.toISOString().split('T')[0];
                            const hasFlight = daysWithFlights[dayString];

                            return (
                                <button
                                    key={dayString}
                                    type="button"
                                    onClick={() => setCurrentDate(new Date(day.date))}
                                    className={classNames(
                                        'py-1.5 hover:bg-gray-100 dark:hover:bg-zinc-600 focus:z-10',
                                        hasFlight
                                            ? 'bg-blue-200 dark:bg-blue-900'
                                            : day.isCurrentMonth
                                                ? 'bg-white dark:bg-zinc-800'
                                                : 'bg-gray-50 dark:bg-zinc-700',
                                        (day.isSelected || day.isToday) && 'font-semibold',
                                        day.isSelected && 'text-white',
                                        !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900 dark:text-gray-100',
                                        !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400 dark:text-gray-500',
                                        day.isToday && !day.isSelected && 'text-indigo-600 dark:text-indigo-400',
                                        dayIdx === 0 && 'rounded-tl-lg',
                                        dayIdx === 6 && 'rounded-tr-lg',
                                        dayIdx === days.length - 7 && 'rounded-bl-lg',
                                        dayIdx === days.length - 1 && 'rounded-br-lg'
                                    )}
                                >
                                    <time
                                        dateTime={dayString}
                                        className={classNames(
                                            'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                            day.isSelected && day.isToday && 'bg-indigo-600 dark:bg-indigo-500',
                                            day.isSelected && !day.isToday && 'bg-gray-900 dark:bg-gray-700'
                                        )}
                                    >
                                        {day.date.getDate()}
                                    </time>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}